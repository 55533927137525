import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';

import classes from './styles.module.scss';

export default function FeaturesFormInModalForm({
  typeForm,
  values,
  setFieldValue,
  setFieldTouched,
  features,
  setFeatures,
  setCurrentPage,
  isCancel,
  resetForm,
  setErrors,
  errors,
  touched,
}) {
  const [feature, setFeature] = useState('');

  const addFeature = () => {
    if (feature.trim() === '') {
      return;
    }
    setFeatures((prevFeatures) => {
      const newFeatures = [...prevFeatures, feature];
      return newFeatures;
    });
    setFeature('');
  };

  const removeFeature = (featureFromTheList) => {
    setFeatures((prevFeatures) => {
      const updatedFeatures = prevFeatures.filter(
        (ftr) => ftr !== featureFromTheList
      );
      return updatedFeatures;
    });
  };

  const isValid = async () => {
    const result = await setFieldTouched(`${typeForm}.description`, true, true);
    if (result.descriptionForm) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isCancel) {
      resetForm(setErrors);
    }
  }, [isCancel]);

  return (
    <div className={classes.FeaturesForm}>
      <div className={classes.container}>
        <div className={classes.header}>
          <h1>Adding Key Features and description</h1>
        </div>
        <div className={classes.descriptionContainer}>
          <span className={classes.heading}>Enter description </span>
          <Form.Control
            as="textarea"
            value={values.description}
            onChange={(event) => {
              setFieldValue(`${typeForm}.description`, event.target.value);
            }}
            placeholder="Description"
            onBlur={() => setFieldTouched(`${typeForm}.description`)}
            isInvalid={
              errors[typeForm] &&
              errors[typeForm].description &&
              touched[typeForm] &&
              touched[typeForm].description
            }
            name="description"
          />
          {errors[typeForm] && errors[typeForm].description ? (
            <Form.Control.Feedback type="invalid">
              {errors[typeForm].description}
            </Form.Control.Feedback>
          ) : null}
        </div>
        <div className={classes.textareaContainer}>
          <span className={classes.heading}>Enter Multiple Key Features </span>
          <textarea
            maxLength={120}
            value={feature}
            onChange={(event) => setFeature(event.target.value)}
          />
          <button type="button" className={classes.button} onClick={addFeature}>
            Add
          </button>
        </div>
        {features.length ? (
          <div className={classes.featuresList}>
            <ul className={classes.itemsContainer}>
              {features.map((ftr) => (
                <li key={ftr}>
                  {ftr}{' '}
                  <i
                    className={classes.removeIcon}
                    onClick={() => removeFeature(ftr)}
                  >
                    remove
                  </i>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <div className={classes.mainButtonsContainer}>
          <button
            type="button"
            className={classes.button}
            onClick={() => setCurrentPage(1)}
          >
            Back
          </button>
          <button
            type="button"
            className={classes.button}
            onClick={async () => {
              const res = await isValid();
              if (features.length && res) {
                setCurrentPage(3);
              }
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
