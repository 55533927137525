import React from 'react';

import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import classes from '../styles.module.scss';

const FeaturesForm = ({ values, setFieldValue, typeForm }) => {
  return (
    <div className={classes.ContainerForm}>
      <div className={classes.header}>
        <h1>Features</h1>
      </div>
      <div className={classes.container}>
        <div className={classes.row}>
          <span>Internet Exchange</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasInternetExchange"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasInternetExchange}
              onChange={() => {
                setFieldValue(`${typeForm}.hasInternetExchange`, true);
              }}
            />
            <Form.Check
              name="hasInternetExchange"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasInternetExchange}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasInternetExchange`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Gym</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasGym"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasGym}
              onChange={() => {
                setFieldValue(`${typeForm}.hasGym`, true);
              }}
            />
            <Form.Check
              name="hasGym"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasGym}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasGym`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Remote Hands</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasRemoteHands"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasRemoteHands}
              onChange={() => {
                setFieldValue(`${typeForm}.hasRemoteHands`, true);
              }}
            />
            <Form.Check
              name="hasRemoteHands"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasRemoteHands}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasRemoteHands`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Dual MPOE</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasDualMPOE"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasDualMPOE}
              onChange={() => {
                setFieldValue(`${typeForm}.hasDualMPOE`, true);
              }}
            />
            <Form.Check
              name="hasDualMPOE"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasDualMPOE}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasDualMPOE`, false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesForm;
