import React from 'react';

import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import classes from '../styles.module.scss';

const CertificationsForm = ({ values, setFieldValue, typeForm }) => {
  return (
    <div className={classes.ContainerForm}>
      <div className={classes.header}>
        <h1>Certifications</h1>
      </div>
      <div className={classes.container}>
        <div className={classes.row}>
          <span>HITRUST</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasHITRUST"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasHITRUST}
              onChange={() => {
                setFieldValue(`${typeForm}.hasHITRUST`, true);
              }}
            />
            <Form.Check
              name="hasHITRUST"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasHITRUST}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasHITRUST`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>PCI DSS 3.2 Validation</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasPCIdss32Validation"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasPCIdss32Validation}
              onChange={() => {
                setFieldValue(`${typeForm}.hasPCIdss32Validation`, true);
              }}
            />
            <Form.Check
              name="hasPCIdss32Validation"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasPCIdss32Validation}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasPCIdss32Validation`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>ISO 27001 Compliant</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="isISO27001Compliant"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.isISO27001Compliant}
              onChange={() => {
                setFieldValue(`${typeForm}.isISO27001Compliant`, true);
              }}
            />
            <Form.Check
              name="isISO27001Compliant"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.isISO27001Compliant}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.isISO27001Compliant`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>NIST Compliant</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="isNISTCompliant"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.isNISTCompliant}
              onChange={() => {
                setFieldValue(`${typeForm}.isNISTCompliant`, true);
              }}
            />
            <Form.Check
              name="isNISTCompliant"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.isNISTCompliant}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.isNISTCompliant`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>SOC 3 Compliant</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="isSOC3Compliant"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.isSOC3Compliant}
              onChange={() => {
                setFieldValue(`${typeForm}.isSOC3Compliant`, true);
              }}
            />
            <Form.Check
              name="isSOC3Compliant"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.isSOC3Compliant}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.isSOC3Compliant`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>HIPAA Compliant</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="isHIPAACompliant"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.isHIPAACompliant}
              onChange={() => {
                setFieldValue(`${typeForm}.isHIPAACompliant`, true);
              }}
            />
            <Form.Check
              name="isHIPAACompliant"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.isHIPAACompliant}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.isHIPAACompliant`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>SOC 2 Type 2 Compliant</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="isSOC2Type2Compliant"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.isSOC2Type2Compliant}
              onChange={() => {
                setFieldValue(`${typeForm}.isSOC2Type2Compliant`, true);
              }}
            />
            <Form.Check
              name="isSOC2Type2Compliant"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.isSOC2Type2Compliant}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.isSOC2Type2Compliant`, false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificationsForm;
