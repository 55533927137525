import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import { actions as providersActions } from '../../../resources/providers';

import CreateDataCenterModal from '../../../modals/CreateDataCenterModal';

import { DEFAULT_PAGE_SIZE } from '../../../constants/main';
import ProvidersList from '../../../components/Dashboard/ProvidersList';

import classes from './styles.module.scss';

const YourDataCenterList = ({
  providers,
  fetchProvidersAction,
  updateProviderAction,
  deleteProviderAction,
  pageIndex,
  totalItemsCount,
}) => {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const fetchProvidersOnPage = (newPageIndex) => {
    fetchProvidersAction(null, {
      query: {
        searchQuery: '',
        filterOption: 'all',
        pageIndex: newPageIndex,
        pageSize: DEFAULT_PAGE_SIZE,
      },
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Add DataCenters
    </Tooltip>
  );

  useEffect(() => {
    fetchProvidersOnPage(pageIndex || 0);
  }, []);

  return (
    <div className={classes.YourDataCenterList}>
      <div className={classes.title}>Your DataCenters</div>
      <div className={classes.dataCenterList}>
        <ProvidersList
          providers={providers}
          pageIndex={pageIndex}
          totalItemsCount={totalItemsCount}
          updateProviderAction={updateProviderAction}
          deleteProviderAction={deleteProviderAction}
          onPageChange={fetchProvidersOnPage}
          fetchProvidersOnPage={fetchProvidersOnPage}
        />
      </div>
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <div
          className={classes.addBtn}
          onClick={() => setIsCreateModalVisible(true)}
        >
          <div className={classes.iconBtn} />
        </div>
      </OverlayTrigger>

      <CreateDataCenterModal
        show={isCreateModalVisible}
        onHide={setIsCreateModalVisible}
        fetchProvidersOnPage={fetchProvidersOnPage}
      />
    </div>
  );
};

export default connect(
  (state) => {
    return {
      providers: state.providers.items,
      isFetching: state.providers.isFetching,
      pageIndex: state.providers.pageIndex,
      totalItemsCount: state.providers.totalItemsCount,
    };
  },
  {
    fetchProvidersAction: providersActions.fetchProviders,
    updateProviderAction: providersActions.updateProvider,
    deleteProviderAction: providersActions.deleteProvider,
  }
)(YourDataCenterList);
