import { combineReducers } from 'redux';

import { rootReducer as users } from '../resources/users';
import { rootReducer as providers } from '../resources/providers';
import { rootReducer as roles } from '../resources/roles';

const reducers = combineReducers({
  users,
  roles,
  providers,
});

export default reducers;
