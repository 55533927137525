import React, { useState, useEffect } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import classes from './styles.module.scss';
import AuthHeader from '../../../components/Layout/AuthHeader';
import { actions as userActions } from '../../../resources/users';

import SelectAccountModal from '../../../modals/SelectAccountModal';
import firstAllowedRoute from '../../../helpers/firstAllowedRoute';
import FormField from '../../../components/FormComponents/FormFieldWithEye';

const ValidationSchema = Yup.object().shape({
  fullName: Yup.string().required('Please enter full name'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().min(8, 'Too Short!').required('Please enter password'),
});

function SignUpPage({ createUser, getUser }) {
  const [error, setError] = useState(null);
  const [isSelectAccountModalVisible, setIsSelectAccountModalVisible] =
    useState(false);
  const [role, setRole] = useState(null);
  const history = useHistory();
  useEffect(() => {
    setIsSelectAccountModalVisible(true);
  }, []);
  console.log(role);
  return (
    <div className={classes.SignUpPage}>
      <AuthHeader />
      <div className={classes.leftColumn} />
      <div className={classes.rightColumn}>
        <div className={classes.wellcomeBlock}>
          <h1>Welcome to Coolbytz</h1>
          <p>Register your account</p>
        </div>
        <div className={classes.signUpForm}>
          <Formik
            initialValues={{
              fullName: '',
              email: '',
              password: '',
              rePassword: '',
              agree: false,
            }}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {
              if (role) {
                createUser({
                  ...values,
                  role,
                })
                  .then(({ body }) => {
                    const { userId } = body;

                    getUser({ id: userId })
                      .then(() => {
                        history.push(firstAllowedRoute());
                      })
                      .catch(() => {
                        setError({
                          message: 'User already exists',
                        });
                      });
                  })
                  .catch(() =>
                    setError({
                      message: 'User already exists',
                    })
                  );
              } else {
                setIsSelectAccountModalVisible(true);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                {error && <div className={classes.Error}>{error.message}</div>}
                <div className={classes.signInputWrapper}>
                  <h5>Name</h5>
                  <div
                    className={classnames(
                      classes.signInput,
                      errors.fullName &&
                        touched.fullName &&
                        errors.fullName &&
                        classes.isInvalid
                    )}
                  >
                    <input
                      type="text"
                      name="fullName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullName}
                    />
                  </div>
                  <span className={classes.errorMessage}>
                    {errors.fullName && touched.fullName && errors.fullName}
                  </span>
                </div>
                <div className={classes.signInputWrapper}>
                  <h5>Email Address</h5>
                  <div
                    className={classnames(
                      classes.signInput,
                      errors.email &&
                        touched.email &&
                        errors.email &&
                        classes.isInvalid
                    )}
                  >
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </div>
                  <span className={classes.errorMessage}>
                    {errors.email && touched.email && errors.email}
                  </span>
                </div>
                <div className={classes.signInputWrapper}>
                  <FormField
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Password"
                    name="password"
                  />
                  <span className={classes.errorMessage}>
                    {errors.password && touched.password && errors.password}
                  </span>
                </div>
                <div
                  className={classnames(
                    classes.signAction,
                    errors.agree &&
                      touched.agree &&
                      errors.agree &&
                      classes.isInvalid
                  )}
                />
                <span
                  className={classnames(
                    classes.errorMessage,
                    errors.agree &&
                      touched.agree &&
                      errors.agree &&
                      classes.lastItem
                  )}
                >
                  {errors.agree && touched.agree && errors.agree}
                </span>
                <button
                  type="submit"
                  className={classes.btnSubmit}
                  disabled={isSubmitting && !role}
                >
                  Sign Up
                </button>
                <div className={classes.linkBox}>
                  <span className={classes.span}>Already have an account?</span>
                  <Link to="/login" className={classes.createAccountBtn}>
                    Log in
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <SelectAccountModal
        show={isSelectAccountModalVisible}
        handleClose={() => setIsSelectAccountModalVisible(false)}
        setRole={setRole}
      />
    </div>
  );
}

export default connect(null, {
  createUser: userActions.createUser,
  getUser: userActions.getUser,
})(SignUpPage);
