/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import classes from './styles.module.scss';
import TypeRadios from '../TypeRadios';

const TechnicalFormCageState = {
  power: '',
  racks: '',
  height: ['42u', '47u'],
  width: ['600mm', '800mm', '1200mm'],
  powerFeeds: [
    'Voltage',
    'AC/DC',
    'Single phase / 3 phase / Both',
    'AMP rating',
  ],
  security: [
    'cctv, biometrics, access card readers',
    'Supply your own',
    'Racks',
    'Pdus',
  ],
};

export default function TechnicalFormCage({
  values,
  allValues,
  setFieldValue,
  setFieldTouched,
  typeForm,
  setCurrentPage,
  technicalFormPage,
  setTechnicalFormPage,
  isEditMode,
  noButtons,
  state,
  setState,
  errors,
  touched,
}) {
  const [oldState, setOldState] = useState({});

  useEffect(() => {
    setOldState({ ...values });
  }, []);

  const changeState = () => {
    setState((prev) => {
      return {
        ...prev,
        technicalFormCage: {
          power: {
            ...state.technicalFormCage.power,
            value: values.power,
          },
          racks: {
            ...state.technicalFormCage.racks,
            value: values.racks,
          },
          height: {
            ...state.technicalFormCage.height,
            value: values.height,
          },
          width: {
            ...state.technicalFormCage.width,
            value: values.width,
          },
          powerFeeds: {
            ...state.technicalFormCage.powerFeeds,
            value: values.powerFeeds,
          },
          security: {
            ...state.technicalFormCage.security,
            value: values.security,
          },
        },
      };
    });
  };

  const isValid = async () => {
    const result = await Promise.all([
      setFieldTouched(`${typeForm}.power`, true, true),
      setFieldTouched(`${typeForm}.racks`, true, true),
      setFieldTouched(`${typeForm}.powerFeeds`, true, true),
      setFieldTouched(`${typeForm}.security`, true, true),
    ]);

    const firstItem = result[0];
    if (firstItem.technicalFormCage) {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.Form}>
      <div className={classes.container}>
        <TypeRadios
          technicalFormPage={technicalFormPage}
          setTechnicalFormPage={setTechnicalFormPage}
          setFieldValue={setFieldValue}
          allValues={allValues}
        />
        <div className={classes.row}>
          <span>Total power required (kW)</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.power}
              onChange={(event) => {
                setFieldValue(`${typeForm}.power`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.power`)}
              isInvalid={
                ((errors[typeForm] && errors[typeForm].power) ||
                  values.power === '') &&
                touched[typeForm] &&
                touched[typeForm].power
              }
              name="power"
            />
            {((errors[typeForm] && errors[typeForm].power) ||
              values.power === '') &&
            touched[typeForm] &&
            touched[typeForm].power ? (
              <Form.Control.Feedback type="invalid">
                {(errors[typeForm] && errors[typeForm].power) ||
                  'This field is required'}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>How many racks?</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.racks}
              onChange={(event) => {
                setFieldValue(`${typeForm}.racks`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.racks`)}
              isInvalid={
                ((errors[typeForm] && errors[typeForm].racks) ||
                  values.racks === '') &&
                touched[typeForm] &&
                touched[typeForm].racks
              }
              name="racks"
            />
            {((errors[typeForm] && errors[typeForm].racks) ||
              values.racks === '') &&
            touched[typeForm] &&
            touched[typeForm].racks ? (
              <Form.Control.Feedback type="invalid">
                {(errors[typeForm] && errors[typeForm].racks) ||
                  'This field is required'}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Height if known</span>
          <Form.Select
            className={classes.select}
            bsPrefix={classnames('form-select', classes.selectPrefix)}
            onChange={(event) => {
              setFieldValue(`${typeForm}.height`, event.target.value);
            }}
            name="height"
          >
            <option selected disabled>
              Please Select
            </option>
            {TechnicalFormCageState.height.map((option) => (
              <option
                key={option}
                value={option}
                selected={values.height === option}
              >
                {option}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className={classes.row}>
          <span>Width if known</span>
          <Form.Select
            className={classes.select}
            bsPrefix={classnames('form-select', classes.selectPrefix)}
            onChange={(event) => {
              setFieldValue(`${typeForm}.width`, event.target.value);
            }}
            name="width"
          >
            <option selected disabled>
              Please Select
            </option>
            {TechnicalFormCageState.width.map((option) => (
              <option
                key={option}
                value={option}
                selected={values.width === option}
              >
                {option}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className={classes.row}>
          <span>What power feeds do you require</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.powerFeeds`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.powerFeeds`)}
              isInvalid={
                ((errors[typeForm] && errors[typeForm].powerFeeds) ||
                  values.powerFeeds === '') &&
                touched[typeForm] &&
                touched[typeForm].powerFeeds
              }
              name="powerFeeds"
            >
              <option selected disabled>
                Please Select
              </option>
              {TechnicalFormCageState.powerFeeds.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.powerFeeds === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {((errors[typeForm] && errors[typeForm].powerFeeds) ||
              values.powerFeeds === '') &&
            touched[typeForm] &&
            touched[typeForm].powerFeeds ? (
              <Form.Control.Feedback type="invalid">
                {(errors[typeForm] && errors[typeForm].powerFeeds) ||
                  'This field is required'}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Do you expect to require additional security?</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.security`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.security`)}
              isInvalid={
                ((errors[typeForm] && errors[typeForm].security) ||
                  values.security === '') &&
                touched[typeForm] &&
                touched[typeForm].security
              }
              name="security"
            >
              <option selected disabled>
                Please Select
              </option>
              {TechnicalFormCageState.security.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.security === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {((errors[typeForm] && errors[typeForm].security) ||
              values.security === '') &&
            touched[typeForm] &&
            touched[typeForm].security ? (
              <Form.Control.Feedback type="invalid">
                {(errors[typeForm] && errors[typeForm].security) ||
                  'This field is required'}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div
          className={classes.buttonsContainer}
          style={{ display: noButtons ? 'none' : 'flex' }}
        >
          <button
            className={classes.formButton}
            type="button"
            onClick={() => {
              setCurrentPage((prevState) => {
                if (prevState === 1 && !isEditMode) {
                  return prevState;
                }
                if (isEditMode) {
                  setFieldValue(`${typeForm}`, oldState);
                  return 5;
                }
                return prevState - 1;
              });
            }}
          >
            {isEditMode ? 'Cancel' : 'Back'}
          </button>
          <button
            className={classes.formButton}
            type="button"
            onClick={async () => {
              await isValid();

              if (
                values.power &&
                values.racks &&
                values.powerFeeds &&
                values.security
              ) {
                setCurrentPage((prevState) => {
                  if (prevState === 4 && !isEditMode) {
                    return prevState;
                  }
                  if (isEditMode) {
                    changeState();
                    return 5;
                  }
                  changeState();
                  return prevState + 1;
                });
              }
            }}
          >
            {isEditMode ? 'Save' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
}
