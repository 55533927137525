/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import axios from 'axios';
import moment from 'moment';
import classnames from 'classnames';

import Layout from '../../containers/Layout';

import { actions as providersActions } from '../../resources/providers';

import LocationMap from '../../components/Detail/LocationMap';
import DataCenterCard from '../../components/Detail/DataCenter';
import ListFeatures from '../../components/Detail/ListFeatures';
import SpecsTab from '../../components/Detail/SpecsTab';

import {
  API_URL,
  DEFAULT_PAGE_SIZE_DETAIL_PAGE,
  STATIC_URL,
} from '../../constants/main';

import searchImg from '../../assets/images/search.svg';

import imgDataCenterMini1 from '../../assets/images/logoDataCenter_Mini_1.svg';
import imgDataCenterMini2 from '../../assets/images/logoDataCenter_Mini_2.svg';
import imgDataCenterMini3 from '../../assets/images/logoDataCenter_Mini_3.svg';

import RadioButton from '../../components/FormComponents/RadioButton';

import classes from './styles.module.scss';

const DetailsPage = ({ isFetchingItem, provider, getProviderAction }) => {
  const history = useHistory();
  const { id } = useParams();
  const [key, setKey] = useState('Description');
  const [option, setOption] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const [items, setItems] = useState([]);

  const [page, setPage] = useState(0);
  const [itemsCount, setTotalItemsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const logo = provider ? JSON.parse(provider.logoPath) : '';

  const changeOption = (event) => {
    setOption(event.target.value);
    setPage(0);
    setItems([]);
  };

  const fetchDataCenter = () => {
    getProviderAction(id);
  };

  const fetchProviders = async () => {
    try {
      setIsLoading(true);
      let response;
      if (searchQuery) {
        response = await axios.get(`${API_URL}/providers/search`, {
          params: {
            searchQuery,
            filterOption: option,
            pageIndex: page,
            pageSize: DEFAULT_PAGE_SIZE_DETAIL_PAGE,
          },
        });
      } else {
        response = await axios.get(`${API_URL}/providers`, {
          params: {
            searchQuery: '',
            filterOption: option,
            pageIndex: page,
            pageSize: DEFAULT_PAGE_SIZE_DETAIL_PAGE,
          },
        });
      }
      setIsLoading(false);

      return response.data;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const fetchData = async () => {
    const { data, totalItemsCount } = await fetchProviders();
    setItems([...items, ...data]);
    setTotalItemsCount(totalItemsCount);

    setPage(page + 1);
  };

  useEffect(() => {
    fetchDataCenter(id);
  }, [id]);

  const handleScroll = (event) => {
    const element = event.target;

    if (
      element.scrollHeight - element.scrollTop === element.clientHeight &&
      itemsCount > items.length
    ) {
      fetchData();
    }
  };

  const fetchDataWithSearch = async () => {
    const response = await axios.get(`${API_URL}/providers/search`, {
      params: {
        searchQuery,
        filterOption: option,
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE_DETAIL_PAGE,
      },
    });
    setItems(response.data.data);
    setPage(1);
  };

  const fetchDataWithOutSearch = async () => {
    const response = await axios.get(`${API_URL}/providers`, {
      params: {
        searchQuery,
        filterOption: option,
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE_DETAIL_PAGE,
      },
    });

    setItems(response.data.data);
  };

  useEffect(() => {
    fetchData();
  }, [option]);

  useEffect(() => {
    if (searchQuery) {
      fetchDataWithSearch();
    } else {
      fetchDataWithOutSearch();
    }
  }, [searchQuery]);

  return (
    <Layout>
      <div
        className={classnames(
          classes.DetailsPage,
          isFetchingItem && classes.isFetchingItem
        )}
      >
        {isFetchingItem ? (
          <div className={classes.loader}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            <LocationMap
              address={provider && provider.address ? provider.address : ''}
              name={
                provider && provider.dataCenterName
                  ? provider.dataCenterName
                  : ''
              }
            />
            <DataCenterCard
              logo={provider && logo.path}
              name={
                provider && provider.dataCenterName
                  ? provider.dataCenterName
                  : ''
              }
              title={provider && provider.title ? provider.title : ''}
              phone={provider && provider.phoneNo ? provider.phoneNo : ''}
              email={provider && provider.email ? provider.email : ''}
              website={provider && provider.website ? provider.website : ''}
              address={provider && provider.address ? provider.address : ''}
            />
            <div className={classes.topFeaturesContainer}>
              <div className={classes.topFeatures}>
                <div className={classes.top}>
                  <div className={classes.title}>Top Features</div>
                </div>
                <ListFeatures
                  features={
                    provider && provider.features ? provider.features : []
                  }
                />
              </div>
              <div className={classes.nearbyDataCenters}>
                <div className={classes.top}>
                  <div className={classes.title}>Nearby Data Centers</div>
                  <div className={classes.seeMore}>See More</div>
                </div>
                <div className={classes.itemsContainer}>
                  <div className={classes.item}>
                    <div className={classes.itemLogo}>
                      <img src={imgDataCenterMini1} alt="" />
                    </div>
                    <div className={classes.description}>
                      <div className={classes.name}>Zenlayer</div>
                      <div className={classes.address}>Moscow, Russia</div>
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.itemLogo}>
                      <img src={imgDataCenterMini2} alt="" />
                    </div>
                    <div className={classes.description}>
                      <div className={classes.name}>Cyxtera</div>
                      <div className={classes.address}>Moscow, Russia</div>
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.itemLogo}>
                      <img src={imgDataCenterMini3} alt="" />
                    </div>
                    <div className={classes.description}>
                      <div className={classes.name}>Datanamics</div>
                      <div className={classes.address}>Moscow, Russia</div>
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.itemLogo}>
                      <img src={imgDataCenterMini1} alt="" />
                    </div>
                    <div className={classes.description}>
                      <div className={classes.name}>Zenlayer</div>
                      <div className={classes.address}>Moscow, Russia</div>
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.itemLogo}>
                      <img src={imgDataCenterMini2} alt="" />
                    </div>
                    <div className={classes.description}>
                      <div className={classes.name}>Cyxtera</div>
                      <div className={classes.address}>Moscow, Russia</div>
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.itemLogo}>
                      <img src={imgDataCenterMini3} alt="" />
                    </div>
                    <div className={classes.description}>
                      <div className={classes.name}>Datanamics</div>
                      <div className={classes.address}>Moscow, Russia</div>
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.itemLogo}>
                      <img src={imgDataCenterMini1} alt="" />
                    </div>
                    <div className={classes.description}>
                      <div className={classes.name}>Zenlayer</div>
                      <div className={classes.address}>Moscow, Russia</div>
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.itemLogo}>
                      <img src={imgDataCenterMini2} alt="" />
                    </div>
                    <div className={classes.description}>
                      <div className={classes.name}>Cyxtera</div>
                      <div className={classes.address}>Moscow, Russia</div>
                    </div>
                  </div>
                  <div className={classes.item}>
                    <div className={classes.itemLogo}>
                      <img src={imgDataCenterMini3} alt="" />
                    </div>
                    <div className={classes.description}>
                      <div className={classes.name}>Datanamics</div>
                      <div className={classes.address}>Moscow, Russia</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.ecosystemContainer}>
              <div className={classes.container}>
                <div className={classes.tabItems}>
                  <ul className={classes.items}>
                    {/* <li
                      className={classnames(
                        classes.item,
                        key === 'Ecosystem' && classes.active
                      )}
                      onClick={() => setKey('Ecosystem')}
                    >
                      Ecosystem
                    </li> */}
                    <li
                      className={classnames(
                        classes.item,
                        key === 'Description' && classes.active
                      )}
                      onClick={() => setKey('Description')}
                    >
                      Description
                    </li>
                    <li
                      className={classnames(
                        classes.item,
                        key === 'Specs' && classes.active
                      )}
                      onClick={() => setKey('Specs')}
                    >
                      Specs
                    </li>
                    <li
                      className={classnames(
                        classes.item,
                        key === 'Media' && classes.active
                      )}
                      onClick={() => setKey('Media')}
                    >
                      Media
                    </li>
                  </ul>
                </div>
                <div className={classes.dateContainer}>
                  <span className={classes.span}>Last Updated:</span>
                  {provider && provider.updatedAt
                    ? moment(provider.updatedAt).format('MMM D, YYYY')
                    : null}
                </div>
              </div>
            </div>
            <div className={classes.contentContainer}>
              {key === 'Ecosystem' && (
                <div className={classes.ecosystemBox}>
                  <div className={classes.searchContainer}>
                    <div className={classes.search}>
                      <input
                        type="text"
                        className={classes.inputSearch}
                        placeholder="Search For Service Providers..."
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <img src={searchImg} alt="" />
                    </div>
                    <div className={classes.filterContainer}>
                      <RadioButton
                        id="option1"
                        label="All"
                        name="all"
                        value="all"
                        isSelected={option === 'all'}
                        changed={changeOption}
                      />
                      <RadioButton
                        id="option2"
                        label="Network"
                        name="network"
                        value="network"
                        isSelected={option === 'network'}
                        changed={changeOption}
                      />
                      <RadioButton
                        id="option3"
                        label="Peering"
                        name="peering"
                        value="peering"
                        isSelected={option === 'peering'}
                        changed={changeOption}
                      />
                      <RadioButton
                        id="option4"
                        label="IaaS"
                        name="iaaS"
                        value="iaaS"
                        isSelected={option === 'iaaS'}
                        changed={changeOption}
                      />
                      <RadioButton
                        id="option5"
                        label="Services"
                        name="services"
                        value="services"
                        isSelected={option === 'services'}
                        changed={changeOption}
                      />
                    </div>
                  </div>
                  <div
                    className={classes.tableContainer}
                    onScroll={handleScroll}
                  >
                    <table className={classes.table}>
                      <thead>
                        <tr className={classes.titleContainer}>
                          <th className={classes.item}>SERVICE PROVIDERS</th>
                          <th className={classes.item}>SERVICES</th>
                          <th className={classes.item}>DC POPS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items
                          ? items.map((item) => (
                              <tr
                                className={classes.itemContainer}
                                onClick={() =>
                                  history.push(`/details/${item.id}`)
                                }
                                key={item.id}
                              >
                                <td className={classes.value}>
                                  {item.dataCenterName}
                                </td>
                                <td className={classes.value}>{item.name}</td>
                                <td className={classes.value}>44</td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                    {isLoading ? (
                      <div className={classes.loadingContainer}>
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              {key === 'Description' && (
                <div className={classes.descriptionBox}>
                  {provider && provider.description ? (
                    <div className={classes.descriptionItem}>
                      {provider.description}
                    </div>
                  ) : null}
                </div>
              )}
              {key === 'Specs' && <SpecsTab provider={provider} />}
              {key === 'Media' && (
                <div className={classes.imagesBox}>
                  <div className={classes.imgItems}>
                    {provider.imagesPaths.map((item) => {
                      const img = JSON.parse(item);
                      return (
                        <div className={classes.imgItem}>
                          <img
                            src={`${STATIC_URL}${img.path}`}
                            alt=""
                            className={classes.img}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default connect(
  (state) => {
    return {
      provider: state.providers.item,
      isFetching: state.providers.isFetching,
      isFetchingItem: state.providers.isFetchingItem,
    };
  },
  {
    fetchProvidersAction: providersActions.fetchProviders,
    getProviderAction: providersActions.getProvider,
  }
)(DetailsPage);
