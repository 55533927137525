import React, { useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import Spinner from 'react-bootstrap/esm/Spinner';

import classes from './styles.module.scss';

const libraries = ['places'];
const mapContainerStyle = {
  height: '578px',
};

const options = {
  zoomControl: true,
};

const LocationMap = ({ address, name }) => {
  const [center, setCenter] = useState({
    lat: 33.75569808435381,
    lng: -84.39105320989381,
  });
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'en',
  });

  const getLatLngAddress = async (a) => {
    try {
      const results = await getGeocode({ address: a });
      const { lat, lng } = await getLatLng(results[0]);
      setCenter({ lat, lng });
    } catch (error) {
      console.log(error);
    }
  };

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    getLatLngAddress(address);
  }, []);

  if (loadError) return 'Error';
  if (!isLoaded) return 'Loading...';

  return (
    <>
      {!isLoaded ? (
        <div className={classes.loader}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className={classes.map}>
          <GoogleMap
            id="map"
            mapContainerStyle={mapContainerStyle}
            zoom={15}
            center={center}
            options={options}
            onLoad={onMapLoad}
          >
            <Marker
              key={`${center.lat}-${center.lng}`}
              position={{ lat: center.lat, lng: center.lng }}
            />
          </GoogleMap>
          <div className={classes.containerAddress}>
            <div className={classes.title}>{name}</div>
            <div className={classes.description}>{address}</div>
          </div>
        </div>
      )}
    </>
  );
};

/* const LocationMap = () => {
  return (
    <div className={classes.map}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d829.3006355232776!2d-84.39185787077183!3d33.75543161381027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5039d2d2266af%3A0x8ad2e24550d7abd3!2sDigital%20Realty!5e0!3m2!1sen!2sua!4v1629726533350!5m2!1sen!2sua"
        height="578px"
        width="100%"
        allowFullScreen=""
        loading="lazy"
        title="map"
      />
      <div className={classes.containerAddress}>
        <div className={classes.title}>Digital Realty ATL13 Data Center</div>
        <div className={classes.description}>
          56 Marietta St NW, 2nd and 9th floor (DRT), Atlanta, GA, 30303, USA
          Operated By Digital Realty
        </div>
      </div>
    </div>
  );
}; */

export default LocationMap;
