/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ProgressFlow from '../../components/QuestionsFormSeller/ProgressFlowInModalForm';
import DetailsForm from '../../components/QuestionsFormSeller/DetailsForm';
import MultiplesForm from '../../components/QuestionsFormSeller/MultipleForms';
import FeaturesForm from '../../components/QuestionsFormSeller/FeaturesForm';
import ImageUploader from '../../components/QuestionsFormSeller/ImageUploader';
import SaveSellerFormModal from '../SaveSellerFormModal';

import ConfirmModal from '../ConfirmModal';

import { API_URL } from '../../constants/main';

import classes from './styles.module.scss';

function formatFormValues(state) {
  const newState = {};
  Object.keys(state).forEach((key) => {
    newState[key] = state[key];
  });
  return newState;
}

function parseUpdatedImages(images) {
  const updatedImages = [];
  images.forEach((image) => {
    updatedImages.push(JSON.parse(image));
  });
  return updatedImages;
}

const ValidationSchema = Yup.object().shape({
  detailsForm: Yup.object().shape({
    name: Yup.string().required('This field is required!'),
    dataCenterName: Yup.string().required('This field is required!'),
    logoPath: Yup.string().required('This field is required!'),
    title: Yup.string().required('This field is required!'),
    email: Yup.string()
      .email('Invalid email')
      .required('This field is required!'),
    address: Yup.string().required('This field is required!'),
    website: Yup.string().required('This field is required!'),
  }),
  descriptionForm: Yup.object().shape({
    description: Yup.string().required('This field is required!'),
  }),
  generalForm: Yup.object().shape({
    propertyTierEquivalent: Yup.string().required('This field is required!'),
    totalPotentialUPSPower: Yup.number()
      .min(1, 'Only positive numbers!')
      .typeError('This field must be a number')
      .required('This field is required!'),
    grossAreaSqFeet: Yup.number()
      .min(1, 'Only positive numbers!')
      .typeError('This field must be a number')
      .required('This field is required!'),
    floorLoadCapacity: Yup.number()
      .min(1, 'Only positive numbers!')
      .typeError('This field must be a number')
      .required('This field is required!'),
    fireSuppressionType: Yup.string().required('This field is required!'),
    fireDetectionType: Yup.string().required('This field is required!'),
  }),
  electricalForm: Yup.object().shape({
    electricalTierEquivalent: Yup.string().required('This field is required!'),
    totalProvisionedUPSPower: Yup.number()
      .min(1, 'Only positive numbers!')
      .typeError('This field must be a number')
      .required('This field is required!'),
    upsType: Yup.string().required('This field is required!'),
    upsRedundancy: Yup.string().required('This field is required!'),
    pduRedundancy: Yup.string().required('This field is required!'),
    generatorRedundancy: Yup.string().required('This field is required!'),
    minPowerCapacityPerRack: Yup.number()
      .min(1, 'Only positive numbers!')
      .typeError('This field must be a number')
      .required('This field is required!'),
  }),
  mechanicalForm: Yup.object().shape({
    mechanicalTierEquivalent: Yup.string().required('This field is required!'),
    crahCRACRedundancy: Yup.string().required('This field is required!'),
    chillerRedundancy: Yup.string().required('This field is required!'),
    cabinetLayout: Yup.string().required('This field is required!'),
    minCoolingCapacityPerRack: Yup.number()
      .min(1, 'Only positive numbers!')
      .typeError('This field must be a number')
      .required('This field is required!'),
  }),
});

export default function CreateDataCenterForm({
  provider,
  show,
  onHide,
  fetchProvidersOnPage,
}) {
  const logo = provider ? JSON.parse(provider.logoPath) : {};
  const initialValues = {
    detailsForm: {
      name: provider ? provider.name : '',
      dataCenterName: provider ? provider.dataCenterName : '',
      logoPath: provider ? logo.filename : '',
      title: provider ? provider.title : '',
      email: provider ? provider.email : '',
      address: provider ? provider.address : '',
      website: provider ? provider.website : '',
      phoneNo: provider ? provider.phoneNo : '',
    },
    descriptionForm: {
      description: provider ? provider.description : '',
    },
    generalForm: {
      propertyTierEquivalent: provider ? provider.propertyTierEquivalent : '',
      totalPotentialUPSPower: provider ? provider.totalPotentialUPSPower : '',
      grossAreaSqFeet: provider ? provider.grossAreaSqFeet : '',
      hasOutside100YearFloodplain: provider
        ? provider.hasOutside100YearFloodplain
        : true,
      hasOutside500YearFloodplain: provider
        ? provider.hasOutside500YearFloodplain
        : true,
      floorLoadCapacity: provider ? provider.floorLoadCapacity : '',
      fireSuppressionType: provider ? provider.fireSuppressionType : '',
      fireDetectionType: provider ? provider.fireDetectionType : '',
    },
    securityForm: {
      onSiteSecurityPersonnel: provider
        ? provider.onSiteSecurityPersonnel
        : true,
      hasMantrap: provider ? provider.hasMantrap : true,
      hasBallisticRatedExteriorWalls: provider
        ? provider.hasBallisticRatedExteriorWalls
        : true,
      hasRemoteMonitoring: provider ? provider.hasRemoteMonitoring : true,
      hasBiometricAuthentication: provider
        ? provider.hasBiometricAuthentication
        : true,
      hasRetinaScanners: provider ? provider.hasRetinaScanners : true,
      hasKeycardAccess: provider ? provider.hasKeycardAccess : true,
      hasDualAuthentication: provider ? provider.hasDualAuthentication : true,
      hasCCTV: provider ? provider.hasCCTV : true,
      hasPerimeterIntrusionDetection: provider
        ? provider.hasPerimeterIntrusionDetection
        : true,
      hasElectricFence: provider ? provider.hasElectricFence : true,
    },
    electricalForm: {
      electricalTierEquivalent: provider
        ? provider.electricalTierEquivalent
        : '',
      totalProvisionedUPSPower: provider
        ? provider.totalProvisionedUPSPower
        : '',
      upsType: provider ? provider.upsType : '',
      upsRedundancy: provider ? provider.upsRedundancy : '',
      pduRedundancy: provider ? provider.pduRedundancy : '',
      generatorRedundancy: provider ? provider.generatorRedundancy : '',
      hasConcurrentlyMaintainableElectric: provider
        ? provider.hasConcurrentlyMaintainableElectric
        : true,
      minPowerCapacityPerRack: provider ? provider.minPowerCapacityPerRack : '',
    },
    mechanicalForm: {
      mechanicalTierEquivalent: provider
        ? provider.mechanicalTierEquivalent
        : '',
      crahCRACRedundancy: provider ? provider.crahCRACRedundancy : '',
      chillerRedundancy: provider ? provider.chillerRedundancy : '',
      cabinetLayout: provider ? provider.cabinetLayout : '',
      hasConcurrentlyMaintainableMechanical: provider
        ? provider.hasConcurrentlyMaintainableMechanical
        : true,
      minCoolingCapacityPerRack: provider
        ? provider.minCoolingCapacityPerRack
        : '',
    },
    certificationsForm: {
      hasHITRUST: provider ? provider.hasHITRUST : true,
      hasPCIdss32Validation: provider ? provider.hasPCIdss32Validation : true,
      isISO27001Compliant: provider ? provider.isISO27001Compliant : true,
      isNISTCompliant: provider ? provider.isNISTCompliant : true,
      isSOC3Compliant: provider ? provider.isSOC3Compliant : true,
      isHIPAACompliant: provider ? provider.isHIPAACompliant : true,
      isSOC2Type2Compliant: provider ? provider.isSOC2Type2Compliant : true,
    },
    featuresForm: {
      hasInternetExchange: provider ? provider.hasInternetExchange : true,
      hasGym: provider ? provider.hasGym : true,
      hasRemoteHands: provider ? provider.hasRemoteHands : true,
      hasDualMPOE: provider ? provider.hasDualMPOE : true,
    },
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [isSaveFormModalVisible, setIsSaveModalVisible] = useState(false);
  const [stateValue, setStateValue] = useState({});
  const [isCancel, setIsCancel] = useState(false);
  const [features, setFeatures] = useState(provider ? provider.features : []);
  const [images, setImages] = useState([]);
  const [imagesUploaded, setImagesUploaded] = useState(
    provider ? parseUpdatedImages(provider.imagesPaths) : []
  );

  const [isCancelCreateModalVisible, setIsCancelCreateModalVisible] =
    useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
    document.body.style.margin = '0';
    return () => {
      document.body.style.backgroundColor = 'unset';
    };
  }, []);

  const requestFormSave = (values) => {
    setStateValue(values);
    setIsSaveModalVisible(true);
  };

  const onConfirm = () => {
    setIsCancel(true);
    setIsCancelCreateModalVisible(false);
    onHide();
  };

  const onCancel = () => {
    setIsCancelCreateModalVisible(true);
  };

  const createOrUpdateProvider = (data) => {
    axios
      .post(`${API_URL}/forms-seller`, data)
      .then(() => {
        setIsSaveModalVisible(false);

        setCurrentPage(1);
        onHide();
        setImagesUploaded([]);
        setFeatures([]);
        fetchProvidersOnPage(0);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitFormData = () => {
    const formData = new FormData();
    const formValues = {
      ...formatFormValues(stateValue.detailsForm),
      ...formatFormValues(stateValue.descriptionForm),
      ...formatFormValues(stateValue.generalForm),
      ...formatFormValues(stateValue.securityForm),
      ...formatFormValues(stateValue.electricalForm),
      ...formatFormValues(stateValue.mechanicalForm),
      ...formatFormValues(stateValue.certificationsForm),
      ...formatFormValues(stateValue.featuresForm),
      features: JSON.stringify(features),
    };

    delete formValues.logoPath;

    images.forEach((image) => {
      formData.append('image', image);
    });

    Object.keys(formValues).forEach((key) => {
      formData.append(key, formValues[key]);
    });

    if (stateValue.detailsForm.logoPath.size) {
      formData.append('logo', stateValue.detailsForm.logoPath);
    } else {
      formData.append('logo', stateValue.detailsForm.logoPath.name);
    }

    if (provider) {
      formData.append('id', provider.id);
      formData.append('imagesUploaded', JSON.stringify(imagesUploaded));
    }

    createOrUpdateProvider(formData);
  };

  const resetForm = (setErrors) => {
    setErrors(initialValues);
    setCurrentPage(1);
    setIsCancel(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => onCancel()}
        dialogClassName={classes.modal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName={classes.modalContent}
      >
        <div className={classes.btnClose} onClick={() => onCancel()}>
          <div className={classes.iconBtn} />
        </div>
        <Modal.Body style={{ padding: 0 }}>
          <div className={classes.QuestionsFormSeller}>
            <ProgressFlow
              currentPage={currentPage}
              pageNames={[
                'Data Center Details',
                'Key Features',
                'Specifications',
                'Upload Media',
                'Finish',
              ]}
            />
            <Formik
              onSubmit={requestFormSave}
              initialValues={initialValues}
              validationSchema={ValidationSchema}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                setErrors,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  {currentPage === 1 && (
                    <DetailsForm
                      onCancel={onCancel}
                      isCancel={isCancel}
                      resetForm={resetForm}
                      setErrors={setErrors}
                      typeForm="detailsForm"
                      values={values.detailsForm}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setCurrentPage={setCurrentPage}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                  {currentPage === 2 && (
                    <FeaturesForm
                      typeForm="descriptionForm"
                      values={values.descriptionForm}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      features={features}
                      setFeatures={setFeatures}
                      setCurrentPage={setCurrentPage}
                      isCancel={isCancel}
                      resetForm={resetForm}
                      setErrors={setErrors}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                  {currentPage === 3 && (
                    <div className={classes.multipleForms}>
                      <MultiplesForm
                        values={values}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        setCurrentPage={setCurrentPage}
                        isCancel={isCancel}
                        resetForm={resetForm}
                        errors={errors}
                        touched={touched}
                        setErrors={setErrors}
                      />
                    </div>
                  )}
                  {(currentPage === 4 || currentPage === 5) && (
                    <ImageUploader
                      isUpdate={!!provider}
                      images={images}
                      setImages={setImages}
                      imagesUploaded={imagesUploaded}
                      setImagesUploaded={setImagesUploaded}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      requestFormSave={requestFormSave}
                      isCancel={isCancel}
                      resetForm={resetForm}
                      setErrors={setErrors}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </div>
          <SaveSellerFormModal
            show={isSaveFormModalVisible}
            handleClose={() => {
              setCurrentPage(4);
              setIsSaveModalVisible(false);
            }}
            onApprove={submitFormData}
            saveDataCenterInFormModal
          />
        </Modal.Body>
      </Modal>
      <ConfirmModal
        show={isCancelCreateModalVisible}
        handleClose={setIsCancelCreateModalVisible}
        onConfirm={onConfirm}
      />
    </>
  );
}
