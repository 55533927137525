export const paginatedFetchMatchMakingReducer = (state, action) => {
  if (action.payload) {
    return {
      ...state,
      items: action.payload.body,
    };
  }
  return {
    ...state,
  };
};
