/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';

// eslint-disable-next-line import/no-named-as-default
import UserMenu from './UserMenu';

import { hasAuthData } from '../../../helpers/authStorage';
import classes from './styles.module.scss';
import firstAllowedRoute from '../../../helpers/firstAllowedRoute';

export default function Header({ inResultPage }) {
  const history = useHistory();
  return (
    <div
      className={classnames(
        classes.Header,
        inResultPage && classes.inResultPage
      )}
    >
      <div
        className={classes.logo}
        onClick={() => history.push(firstAllowedRoute())}
      />
      {hasAuthData() ? (
        <UserMenu />
      ) : (
        <div className={classes.loginContainer}>
          <Link to="/login" className={classes.loginLink}>
            Login
          </Link>
          <Link to="/signup">
            <div className={classes.signupLink}>Sign Up</div>
          </Link>
        </div>
      )}
    </div>
  );
}
