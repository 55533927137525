import React from 'react';

// import classnames from 'classnames';

import Modal from 'react-bootstrap/Modal';
import classes from './styles.module.scss';

export default function SaveSellerFormModal({
  show,
  handleClose,
  onApprove,
  saveDataCenterInFormModal,
}) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName={classes.SaveSellerFormModal}
      backdropClassName={saveDataCenterInFormModal && classes.backDrop}
      dialogClassName={classes.dialog}
    >
      <div className={classes.header}>
        <i
          className={classes.closeIcon}
          tabIndex={0}
          role="button"
          onClick={handleClose}
        >
          Close
        </i>
      </div>
      <div className={classes.message}>
        <p className={classes.title}>Are you sure to save all changes?</p>
        <p className={classes.subtitle}>No Changes were done</p>
      </div>
      <div className={classes.buttonsContainer}>
        <button
          type="button"
          onClick={handleClose}
          className={classes.noButton}
        >
          No
        </button>
        <button type="submit" className={classes.yesButton} onClick={onApprove}>
          {saveDataCenterInFormModal ? 'Save & Finish' : 'Yes'}
        </button>
      </div>
    </Modal>
  );
}
