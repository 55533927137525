import React from 'react';

// import classnames from 'classnames';

import Modal from 'react-bootstrap/Modal';
import classes from './styles.module.scss';

export default function ConfirmModal({
  show,
  handleClose,
  onConfirm,
  isDeleteConfirm,
  id,
}) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName={classes.CancelDataFormModal}
      backdropClassName={classes.backDrop}
      dialogClassName={classes.dialog}
    >
      <div className={classes.header}>
        <i
          className={classes.closeIcon}
          tabIndex={0}
          role="button"
          onClick={() => handleClose()}
        >
          Close
        </i>
      </div>
      <div className={classes.message}>
        <p className={classes.title}>
          {isDeleteConfirm
            ? 'Want to Delete'
            : 'Want to Unsave the Datacenter Details ?'}
        </p>
        <p className={classes.subtitle}>
          {isDeleteConfirm
            ? 'Are you sure want to delete the datacenter? You will not be able to recover them.'
            : 'You will not be able to recover them.'}
        </p>
      </div>
      <div className={classes.buttonsContainer}>
        <button
          type="button"
          onClick={() => handleClose()}
          className={classes.noButton}
        >
          No
        </button>
        <button
          type="button"
          className={classes.yesButton}
          onClick={() => onConfirm(id)}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
}
