import React from 'react';

import { STATIC_URL } from '../../../constants/main';

import classes from './styles.module.scss';

const DataCenterCard = ({
  name,
  title,
  phone,
  email,
  website,
  address,
  logo,
}) => {
  return (
    <div className={classes.dataCenter}>
      <div className={classes.logo}>
        <img src={`${STATIC_URL}${logo}`} alt="" />
      </div>
      <div className={classes.informationContainer}>
        <div className={classes.box}>
          <div className={classes.item}>
            <div className={classes.key}>Name</div>
            <div className={classes.value}>{name}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.key}>Title</div>
            <div className={classes.value}>{title}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.key}>Phone</div>
            <div className={classes.value}>{phone}</div>
          </div>
        </div>
        <div className={classes.box}>
          <div className={classes.item}>
            <div className={classes.key}>Email</div>
            <div className={classes.value}>{email}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.key}>Website</div>
            <div className={classes.value}>{website}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.key}>Address</div>
            <div className={classes.value}>{address}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCenterCard;
