import React, { useCallback, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';

import classes from './styles.module.scss';
import folderIcon from '../../../assets/images/folder.svg';
import ImagePreview from './ImagePreview';

export default function ImageUploaderInModalForm({
  isUpdate,
  images,
  setImages,
  imagesUploaded,
  setImagesUploaded,
  setCurrentPage,
  requestFormSave,
  currentPage,
  isCancel,
  setErrors,
  resetForm,
}) {
  const onDrop = useCallback((acceptedFiles) => {
    setImages((prevImages) => {
      const newImages = [...prevImages, ...acceptedFiles];
      return newImages;
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  });

  const deleteImage = (imageName) => {
    setImages((prevImages) => {
      const updatedImages = prevImages.filter(
        (image) => image.name !== imageName
      );
      return updatedImages;
    });
  };

  const deleteImageUploaded = (imageName) => {
    setImagesUploaded((prevImages) => {
      const updatedImages = prevImages.filter(
        (image) => image.filename !== imageName
      );
      return updatedImages;
    });
  };
  useEffect(() => {
    if (isCancel) {
      resetForm(setErrors);
    }
  }, [isCancel]);
  return (
    <div className={classes.ImageUploaderInModalForm}>
      <div className={classes.container}>
        <div className={classes.header}>
          <span className={classes.title}>Upload your files</span>
          <span className={classes.subtitle}>File should be .jpg or .png</span>
        </div>
        <div {...getRootProps()} className={classes.uploader}>
          <input {...getInputProps()} />
          <img src={folderIcon} alt="folder icon" />
          <p>Drag & Drop your files here</p>
        </div>
        <div className={classes.imagesList}>
          {images.map((image) => {
            return (
              <ImagePreview
                key={image.name}
                image={image}
                onDelete={() => deleteImage(image.name)}
              />
            );
          })}
          {imagesUploaded.map((image) => {
            return (
              <ImagePreview
                isUpdate={isUpdate}
                key={image.name}
                image={image}
                onDelete={() => deleteImageUploaded(image.filename)}
              />
            );
          })}
        </div>
        <div className={classes.buttonsContainer}>
          <button type="button" onClick={() => setCurrentPage(3)}>
            Back
          </button>
          <button
            type="submit"
            onClick={() => {
              setCurrentPage(5);
              requestFormSave();
            }}
          >
            {currentPage === 5 ? 'Save & Next' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
}
