import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';

import classnames from 'classnames';

import classes from './styles.module.scss';

const CommercialsFormState = {
  contractLength: ['12 months', 'Greater than 12 months'],
  currency: ['GBP', 'USD'],
  terms: ['Yes', 'No'],
  additionalServices: ['Yes', 'No'],
};

const CommercialsForm = ({
  typeForm,
  setFieldValue,
  setFieldTouched,
  values,
  setCurrentPage,
  isEditMode,
  state,
  setState,
  errors,
  touched,
}) => {
  const [oldState, setOldState] = useState({});

  useEffect(() => {
    setOldState({ ...values });
  }, []);

  const changeState = () => {
    setState((prev) => {
      return {
        ...prev,
        commercialsForm: {
          contractLength: {
            ...state.commercialsForm.contractLength,
            value: values.contractLength,
          },
          currency: {
            ...state.commercialsForm.currency,
            value: values.currency,
          },
          terms: {
            ...state.commercialsForm.terms,
            value: values.terms,
          },
          additionalServices: {
            ...state.commercialsForm.additionalServices,
            value: values.additionalServices,
          },
        },
      };
    });
  };

  const isValid = async () => {
    const result = await Promise.all([
      setFieldTouched(`${typeForm}.contractLength`, true, true),
      setFieldTouched(`${typeForm}.currency`, true, true),
      setFieldTouched(`${typeForm}.terms`, true, true),
      setFieldTouched(`${typeForm}.additionalServices`, true, true),
    ]);
    const firstItem = result[0];
    if (firstItem.commercialsForm) {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.Form}>
      <div className={classes.container}>
        <div className={classes.row}>
          <span>Contract Length</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.contractLength`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.contractLength`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].contractLength &&
                touched[typeForm] &&
                touched[typeForm].contractLength
              }
              name="contractLength"
            >
              <option selected disabled>
                Please Select
              </option>
              {CommercialsFormState.contractLength.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.contractLength === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] &&
            errors[typeForm].contractLength &&
            touched[typeForm] &&
            touched[typeForm].contractLength ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].contractLength}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Billing Currency</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.currency`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.currency`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].currency &&
                touched[typeForm] &&
                touched[typeForm].currency
              }
              name="currency"
            >
              <option selected disabled>
                Please Select
              </option>
              {CommercialsFormState.currency.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.currency === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] &&
            errors[typeForm].currency &&
            touched[typeForm] &&
            touched[typeForm].currency ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].currency}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>
            Do you have terms and conditiond with any other suppliers?
          </span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.terms`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.terms`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].terms &&
                touched[typeForm] &&
                touched[typeForm].terms
              }
              name="terms"
            >
              <option selected disabled>
                Please Select
              </option>
              {CommercialsFormState.terms.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.terms === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] &&
            errors[typeForm].terms &&
            touched[typeForm] &&
            touched[typeForm].terms ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].terms}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Do you need additional services?</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.additionalServices`,
                  event.target.value
                );
              }}
              onBlur={() => setFieldTouched(`${typeForm}.additionalServices`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].additionalServices &&
                touched[typeForm] &&
                touched[typeForm].additionalServices
              }
              name="additionalServices"
            >
              <option selected disabled>
                Please Select
              </option>
              {CommercialsFormState.additionalServices.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.additionalServices === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] &&
            errors[typeForm].additionalServices &&
            touched[typeForm] &&
            touched[typeForm].additionalServices ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].additionalServices}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          <button
            className={classes.formButton}
            type="button"
            onClick={() => {
              setCurrentPage((prevState) => {
                if (prevState === 1 && !isEditMode) {
                  return prevState;
                }
                if (isEditMode) {
                  setFieldValue(`${typeForm}`, oldState);
                  return 5;
                }
                return prevState - 1;
              });
            }}
          >
            {isEditMode ? 'Cancel' : 'Back'}
          </button>
          {!isEditMode ? (
            <button
              className={classnames(classes.formButton, classes.finish)}
              type="button"
              onClick={async () => {
                const res = await isValid();
                if (res) {
                  setCurrentPage((prevState) => {
                    changeState();
                    return prevState + 1;
                  });
                }
              }}
            >
              Finish
            </button>
          ) : (
            <button
              className={classes.formButton}
              type="button"
              onClick={async () => {
                const res = await isValid();
                if (res) {
                  setCurrentPage((prevState) => {
                    if (prevState === 4 && !isEditMode) {
                      return prevState;
                    }
                    if (isEditMode) {
                      changeState();
                      return 5;
                    }
                    changeState();
                    return prevState + 1;
                  });
                }
              }}
            >
              {isEditMode ? 'Save' : 'Next'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommercialsForm;
