import { createResource } from 'redux-rest-resource';

import { API_URL } from '../constants/main';

export const { types, actions, rootReducer } = createResource({
  name: 'role',
  url: `${API_URL}/roles/:id`,

  actions: {
    fetch: {
      url: `${API_URL}/roles`,
    },
  },
});
