import React, { useState } from 'react';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import ReactFileReader from 'react-file-reader';

import classnames from 'classnames';
import axios from 'axios';

import { actions as userActions } from '../../../resources/users';

import InputForm from '../InputForm';

import { API_URL } from '../../../constants/main';

import classes from './styles.module.scss';

function formatFormValues(state) {
  const newState = {};
  Object.keys(state).forEach((key) => {
    newState[key] = state[key].value;
  });
  return newState;
}

const Account = ({ user, company, getUser }) => {
  const history = useHistory();
  const logo = company ? JSON.parse(company.logoPath) : '';
  const [accountForm, setAccountForm] = useState({
    fullName: {
      label: 'Full Name',
      value: user ? user.fullName : '',
      placeholder: 'Full Name',
      name: 'fullName',
    },
    companyName: {
      label: 'Company Name',
      value: company ? company.companyName : '',
      placeholder: 'Company Name',
      name: 'companyName',
    },
    logoPath: {
      label: 'Company Logo',
      value: {
        name: logo ? logo.filename : '',
      },
      placeholder: 'Choose File (.jpg, .png)',
      type: 'file',
      name: 'logoPath',
    },
    email: {
      label: 'Company Email Address',
      value: company ? company.email : '',
      placeholder: 'email address',
      name: 'email',
    },
    website: {
      label: 'Company Website URL',
      value: company ? company.website : '',
      placeholder: 'Enter Website URL',
      name: 'website',
    },
    phoneNo: {
      label: 'Phone No',
      value: company ? company.phoneNo : '',
      placeholder: 'Type Phone No',
      name: 'phoneNo',
    },
  });

  const handleChange = (e) => {
    setAccountForm({
      ...accountForm,
      [e.target.name]: {
        ...accountForm[e.target.name],
        value: e.target.value,
      },
    });
  };

  const getFileFromUploader = (files) => {
    setAccountForm({
      ...accountForm,
      logoPath: { ...accountForm.logoPath, value: files[0] },
    });
  };

  const updateAccount = () => {
    const formData = new FormData();
    const formValues = {
      ...formatFormValues(accountForm),
    };

    delete formValues.logoPath;

    Object.keys(formValues).forEach((key) => {
      formData.append(key, formValues[key]);
    });

    if (accountForm.logoPath.value.size) {
      formData.append('logo', accountForm.logoPath.value);
    } else {
      formData.append('logo', accountForm.logoPath.value.name);
    }

    axios
      .post(`${API_URL}/company/update`, formData)
      .then(() => {
        getUser({ id: user.id })
          .then(() => {
            history.push('/dashboard/your-data-centers');
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const clearLogo = (e) => {
    e.stopPropagation();
    setAccountForm({
      ...accountForm,
      logoPath: { ...accountForm.logoPath, value: '' },
    });
  };

  return (
    <div className={classes.Account}>
      <div>
        <div className={classes.title}>Account Setting</div>
        <div className={classes.form}>
          <div className={classes.row}>
            <InputForm state={accountForm.fullName} onChange={handleChange} />
            <InputForm
              state={accountForm.companyName}
              onChange={handleChange}
            />
          </div>
          <div className={classes.row}>
            <div className={classes.inputForm}>
              <div className={classes.label}>Company Logo</div>
              <ReactFileReader
                handleFiles={(files) => getFileFromUploader(files)}
                multipleFiles={false}
                fileTypes={['.jpg', '.png']}
              >
                <div className={classes.fileUploader}>
                  <div className={classes.fileDetails}>
                    <div
                      className={classnames(
                        classes.valueBox,
                        accountForm.logoPath.value === '' && classes.empty
                      )}
                    >
                      <div className={classes.value}>
                        {accountForm.logoPath.value
                          ? accountForm.logoPath.value.name
                          : accountForm.logoPath.placeholder}
                      </div>
                      <div
                        className={classes.removeBtn}
                        onClick={(e) => clearLogo(e)}
                      />
                    </div>
                  </div>
                </div>
              </ReactFileReader>
            </div>
            <InputForm state={accountForm.email} onChange={handleChange} />
          </div>
          <div className={classes.row}>
            <InputForm state={accountForm.website} onChange={handleChange} />
            <InputForm state={accountForm.phoneNo} onChange={handleChange} />
          </div>
        </div>
      </div>
      <div className={classes.btnsAction}>
        <button
          type="button"
          className={classnames(classes.button, classes.updateBtn)}
          onClick={updateAccount}
        >
          Update
        </button>
        <button
          type="button"
          className={classnames(classes.button, classes.cancelBtn)}
          onClick={() => history.push('/dashboard/your-data-centers')}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default connect(null, {
  getUser: userActions.getUser,
})(Account);
