/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React /*  { useState } */ from 'react';
import { Link /* useHistory */ } from 'react-router-dom';

import classnames from 'classnames';
import sidebarItems from '../../assets/JsonData/sidebar_routes.json';
import classes from './styles.module.scss';

const SidebarItem = ({ title, active, classCurrentItem }) => {
  let currentItem = '';
  switch (classCurrentItem) {
    case 'topDataCenters':
      currentItem = classes.topDataCenters;
      break;
    case 'yourDataCenters':
      currentItem = classes.yourDataCenters;
      break;
    case 'manage':
      currentItem = classes.manage;
      break;
    case 'analytics':
      currentItem = classes.analytics;
      break;
    case 'support':
      currentItem = classes.support;
      break;
    case 'settings':
      currentItem = classes.help;
      break;
    default:
      break;
  }
  return (
    <div className={classes.item}>
      <div
        className={classnames(classes.borderBox, active && classes.active)}
      />
      <div
        className={classnames(
          classes.itemTitleContainer,
          currentItem,
          active && classes.active
        )}
      >
        <div className={classes.icon} />
        <div className={classes.title}>{title}</div>
      </div>
    </div>
  );
};

export default function Sidebar({ location }) {
  const activeItem = sidebarItems.findIndex(
    (item) => item.route === location.pathname
  );

  return (
    <div className={classes.Sidebar}>
      <div className={classes.logoContainer}>
        <Link to="/dashboard/your-data-centers">
          <div className={classes.logo} />
        </Link>
      </div>
      <div className={classes.menuContainer}>
        <div className={classes.tabItems}>
          {sidebarItems.map((item, index) => (
            <Link to={item.route} key={item.id} className={classes.itemLink}>
              <SidebarItem
                title={item.display_name}
                classCurrentItem={item.classCurrentItem}
                active={index === activeItem}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
/* export default function Sidebar() {
  const history = useHistory();
  const [key, setKey] = useState('topDataCenter');

  const changePage = (page) => {
    setKey(page);
    switch (page) {
      case 'topDataCenter':
        history.push('/dashboard/top-data-centers');
        break;
      case 'YourDataCenters':
        history.push('/dashboard/your-data-centers');
        break;
      case 'Manage':
        history.push('/dashboard/manage');
        break;
      case 'Analytics':
        history.push('/dashboard/analytics');
        break;
      case 'Support':
        history.push('/dashboard/support');
        break;
      case 'Help':
        history.push('/dashboard/help');
        break;
      default:
        history.push('/dashboard/top-data-centers');
        break;
    }
  };
  return (
    <div className={classes.Sidebar}>
      <div className={classes.logoContainer}>
        <Link to="/dashboard/top-data-centers">
          <div className={classes.logo} />
        </Link>
      </div>
      <div className={classes.menuContainer}>
        <div className={classes.tabItems}>
          <ul className={classes.items}>
            <li
              className={classes.item}
              onClick={() => changePage('topDataCenter')}
            >
              <div
                className={classnames(
                  classes.borderBox,
                  key === 'topDataCenter' && classes.active
                )}
              />
              <div
                className={classnames(
                  classes.liItem,
                  classes.topDataCenter,
                  key === 'topDataCenter' && classes.active
                )}
              >
                <div className={classes.icon} />
                <div className={classes.title}>Dashboard</div>
              </div>
            </li>
            <li
              className={classes.item}
              onClick={() => changePage('YourDataCenters')}
            >
              <div
                className={classnames(
                  classes.borderBox,
                  key === 'YourDataCenters' && classes.active
                )}
              />
              <div
                className={classnames(
                  classes.liItem,
                  classes.yourDataCenters,
                  key === 'YourDataCenters' && classes.active
                )}
              >
                <div className={classes.icon} />
                <div className={classes.title}>Your DataCenters</div>
              </div>
            </li>
            <li className={classes.item} onClick={() => changePage('Manage')}>
              <div
                className={classnames(
                  classes.borderBox,
                  key === 'Manage' && classes.active
                )}
              />
              <div
                className={classnames(
                  classes.liItem,
                  classes.manage,
                  key === 'Manage' && classes.active
                )}
              >
                <div className={classes.icon} />
                <div className={classes.title}>Manage</div>
              </div>
            </li>
            <li
              className={classes.item}
              onClick={() => changePage('Analytics')}
            >
              <div
                className={classnames(
                  classes.borderBox,
                  key === 'Analytics' && classes.active
                )}
              />
              <div
                className={classnames(
                  classes.liItem,
                  classes.analytics,
                  key === 'Analytics' && classes.active
                )}
              >
                <div className={classes.icon} />
                <div className={classes.title}>Analytics</div>
              </div>
            </li>
            <li className={classes.item} onClick={() => changePage('Support')}>
              <div
                className={classnames(
                  classes.borderBox,
                  key === 'Support' && classes.active
                )}
              />
              <div
                className={classnames(
                  classes.liItem,
                  classes.support,
                  key === 'Support' && classes.active
                )}
              >
                <div className={classes.icon} />
                <div className={classes.title}>Support</div>
              </div>
            </li>
            <li className={classes.item} onClick={() => changePage('Help')}>
              <div
                className={classnames(
                  classes.borderBox,
                  key === 'Help' && classes.active
                )}
              />
              <div
                className={classnames(
                  classes.liItem,
                  classes.help,
                  key === 'Help' && classes.active
                )}
              >
                <div className={classes.icon} />
                <div className={classes.title}>Help</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
} */
