import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import logoZen from '../../assets/images/logoZen.png';
import logoSad from '../../assets/images/logoSad.png';
import logoHandyNetworks from '../../assets/images/logoHandyNetworks.png';

import classes from './styles.module.scss';

export default function MatchMakingFormCompletion({ show, onHide }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={classes.modalContent}
    >
      <Modal.Body style={{ padding: 0 }}>
        <div className={classes.boxInner}>
          <div className={classes.text}>
            We&apos;re matchmaking on Coolbytz based on your requirements..
          </div>
          <div className={classes.boxContent}>
            <div className={classes.container}>
              <div className={classes.title}>
                The only integarted marketplace for data centre space
              </div>
              <div className={classes.logosContainer}>
                <div className={classes.logoItem}>
                  <img src={logoSad} alt="" />
                </div>
                <div className={classes.logoItem}>
                  <img src={logoZen} alt="" />
                </div>
                <div className={classes.logoItem}>
                  <img src={logoHandyNetworks} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.loader}>
            <Spinner animation="border" variant="primary" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
