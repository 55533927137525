/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import { COUNTRIES } from '../../../constants/main';

import classes from './styles.module.scss';

const LocationState = {
  region: ['America', 'EMEA', 'Asia PAC'],
  drill: COUNTRIES.split(','),
  drillCountry: '',
  town: '',
  searchRadius: [
    'This area only',
    'Within ¼ mile',
    'Within ½ mile',
    'Within 1 mile',
    'Within 3 miles',
    'Within 5 miles',
    'Within 10 miles',
    'Within 15 miles',
    'Within 20 miles',
    'Within 30 miles',
    'Within 40 miles',
  ],
  reason: [
    'Migration from another site',
    'Increase capacity',
    'New Business Requirement',
    'Budgeting Purposes',
  ],
  important: ['Cost', 'Tier Rating'],
  important2: ['Cost', 'Connectivity'],
  important3: ['Cost', 'Supplier Performance'],
};

export default function LocationForm({
  typeForm,
  values,
  setFieldValue,
  setFieldTouched,
  setCurrentPage,
  isEditMode,
  setState,
  state,
  errors,
  touched,
}) {
  const [oldState, setOldState] = useState({});

  useEffect(() => {
    setOldState({ ...values });
  }, []);

  const changeState = () => {
    setState((prev) => {
      return {
        ...prev,
        locationForm: {
          region: {
            ...state.locationForm.region,
            value: values.region,
          },
          drill: {
            ...state.locationForm.drill,
            value: values.drill,
          },
          drillCountry: {
            ...state.locationForm.drillCountry,
            value: values.drillCountry,
          },
          town: {
            ...state.locationForm.town,
            value: values.town,
          },
          searchRadius: {
            ...state.locationForm.searchRadius,
            value: values.searchRadius,
          },
          reason: {
            ...state.locationForm.reason,
            value: values.reason,
          },
          important: {
            ...state.locationForm.important,
            value: values.important,
          },
          important2: {
            ...state.locationForm.important2,
            value: values.important2,
          },
          important3: {
            ...state.locationForm.important3,
            value: values.important3,
          },
        },
      };
    });
  };

  const isValid = async () => {
    const result = await Promise.all([
      setFieldTouched(`${typeForm}.region`, true, true),
      setFieldTouched(`${typeForm}.drill`, true, true),
      setFieldTouched(`${typeForm}.drillCountry`, true, true),
      setFieldTouched(`${typeForm}.town`, true, true),
      setFieldTouched(`${typeForm}.searchRadius`, true, true),
      setFieldTouched(`${typeForm}.reason`, true, true),
      setFieldTouched(`${typeForm}.important`, true, true),
      setFieldTouched(`${typeForm}.important2`, true, true),
      setFieldTouched(`${typeForm}.important3`, true, true),
    ]);
    const firstItem = result[0];
    if (firstItem.locationForm) {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.Form}>
      <div className={classes.container}>
        <div className={classes.row}>
          <span>Region</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.region`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.region`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].region &&
                touched[typeForm] &&
                touched[typeForm].region
              }
              name="region"
            >
              <option selected disabled>
                Please Select
              </option>
              {LocationState.region.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.region === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].region ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].region}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Drill down to country</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.drill`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.drill`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].drill &&
                touched[typeForm] &&
                touched[typeForm].drill
              }
              name="drill"
            >
              <option selected disabled>
                Please Select
              </option>
              {LocationState.drill.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.drill === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].drill ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].drill}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Drill down to county/state (optional)</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.drillCountry}
              onChange={(event) => {
                setFieldValue(`${typeForm}.drillCountry`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.drillCountry`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].drillCountry &&
                touched[typeForm] &&
                touched[typeForm].drillCountry
              }
              name="drillCountry"
            />
            {errors[typeForm] && errors[typeForm].drillCountry ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].drillCountry}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Town (optional)</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.town}
              onChange={(event) => {
                setFieldValue(`${typeForm}.town`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.town`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].town &&
                touched[typeForm] &&
                touched[typeForm].town
              }
              name="town"
            />
            {errors[typeForm] && errors[typeForm].town ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].town}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Search Radius (this area only, +N miles)</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.searchRadius`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.searchRadius`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].searchRadius &&
                touched[typeForm] &&
                touched[typeForm].searchRadius
              }
              name="searchRadius"
            >
              <option selected disabled>
                Please Select
              </option>
              {LocationState.searchRadius.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.searchRadius === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].searchRadius ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].searchRadius}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Reason for facility</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.reason`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.reason`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].reason &&
                touched[typeForm] &&
                touched[typeForm].reason
              }
              name="reason"
            >
              <option selected disabled>
                Please Select
              </option>
              {LocationState.reason.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.reason === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].reason ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].reason}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.buttonsContainer} style={{ display: 'flex' }}>
          <button
            className={classes.formButton}
            type="button"
            onClick={() => {
              setCurrentPage((prevState) => {
                if (prevState === 1 && !isEditMode) {
                  return prevState;
                }
                if (isEditMode) {
                  setFieldValue(`${typeForm}`, oldState);
                  return 5;
                }
                return prevState - 1;
              });
            }}
          >
            {isEditMode ? 'Cancel' : 'Back'}
          </button>
          <button
            className={classes.formButton}
            type="button"
            onClick={async () => {
              const res = await isValid();
              if (res) {
                setCurrentPage((prevState) => {
                  if (prevState === 4 && !isEditMode) {
                    return prevState;
                  }
                  if (isEditMode) {
                    changeState();
                    return 5;
                  }
                  changeState();
                  return prevState + 1;
                });
              }
            }}
          >
            {isEditMode ? 'Save' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
}
