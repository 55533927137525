/* eslint-disable array-callback-return */
import React, { useEffect, useState, useRef } from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Layout from '../../containers/Layout';

import ProgressFlow from '../../components/QuestionsForm/ProgressFlow';
import FilledOutSections from '../../components/QuestionsForm/FilledOutSections';
import BusinessForm from '../../components/QuestionsForm/BusinessForm';
import LocationForm from '../../components/QuestionsForm/LocationForm';
import TechnicalFormCage from '../../components/QuestionsForm/TechnicalForm/TechnicalFormCage';
import TechnicalFormRacks from '../../components/QuestionsForm/TechnicalForm/TechnicalFormRacks';
import TechnicalFormWholesale from '../../components/QuestionsForm/TechnicalForm/TechnicalFormWholesale';
import CommercialsForm from '../../components/QuestionsForm/CommercialsForm';

import MatchMakingFormCompletion from '../../modals/MatchMakingFormCompletionModal';

import { actions as userActions } from '../../resources/users';
import { actions as providersActions } from '../../resources/providers';

import { hasAuthData } from '../../helpers/authStorage';

import LoginForm from './LoginForm';

import classes from './styles.module.scss';

const initialValues = {
  businessForm: {
    purpose: '',
    timelines: '',
    sector: '',
    reason: '',
    important: '',
    important2: '',
    important3: '',
  },
  locationForm: {
    region: '',
    drill: '',
    drillCountry: '',
    town: '',
    searchRadius: '',
    reason: '',
  },
  technicalFormCage: {
    power: '',
    racks: '',
    height: '',
    width: '',
    powerFeeds: '',
    security: '',
  },
  technicalFormRacks: {
    racks: '',
    powerPerRack: '',
    height: '',
    width: '',
    powerFeedsColo: '',
  },
  technicalFormWholesale: {
    space: '',
    power: '',
  },
  commercialsForm: {
    contractLength: '',
    currency: '',
    terms: '',
    additionalServices: '',
  },
};

const ValidationSchema = Yup.object().shape({
  businessForm: Yup.object().shape({
    purpose: Yup.string().required('This field is required!'),
    timelines: Yup.string().required('This field is required!'),
    sector: Yup.string().required('This field is required!'),
    reason: Yup.string().required('This field is required!'),
    important: Yup.string().required('This field is required!'),
    important2: Yup.string().required('This field is required!'),
    important3: Yup.string().required('This field is required!'),
  }),
  locationForm: Yup.object().shape({
    region: Yup.string().required('This field is required!'),
    drill: Yup.string().required('This field is required!'),
    drillCountry: Yup.string().matches(
      /^[aA-zZ\s]+$/,
      'Only letters are allowed for this field'
    ),
    town: Yup.string().matches(
      /^[aA-zZ\s]+$/,
      'Only letters are allowed for this field'
    ),
    searchRadius: Yup.string().required('This field is required!'),
    reason: Yup.string().required('This field is required!'),
  }),
  technicalFormCage: Yup.object().shape({
    power: Yup.number()
      .min(1, 'Only positive numbers!')
      .typeError('This field must be a number'),
    racks: Yup.number()
      .min(1, 'Only positive numbers!')
      .typeError('This field must be a number'),
    powerFeeds: Yup.string(),
    security: Yup.string(),
  }),
  technicalFormRacks: Yup.object().shape({
    racks: Yup.number()
      .min(1, 'Only positive numbers!')
      .typeError('This field must be a number'),
    powerPerRack: Yup.number()
      .min(1, 'Only positive numbers!')
      .typeError('This field must be a number'),
    powerFeedsColo: Yup.string(),
  }),
  technicalFormWholesale: Yup.object().shape({
    space: Yup.number()
      .min(1, 'Only positive numbers!')
      .typeError('This field must be a number'),
    power: Yup.number()
      .min(1, 'Only positive numbers!')
      .typeError('This field must be a number'),
  }),
  commercialsForm: Yup.object().shape({
    contractLength: Yup.string().required('This field is required!'),
    currency: Yup.string().required('This field is required!'),
    terms: Yup.string().required('This field is required!'),
    additionalServices: Yup.string().required('This field is required!'),
  }),
});

const QuestionsFormPage = ({ matchMakingProvidersAction, createUser }) => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [state, setState] = useState({
    businessForm: {
      purpose: {
        label: 'Purpose of the datacenter?',
        value: '',
      },
      timelines: {
        label: 'Timelines',
        value: '',
      },
      sector: {
        label: 'What sector do you operate in?',
        value: '',
      },
      reason: {
        label: 'Reason for facility',
        value: '',
      },
      important: {
        label: 'What is most important to you?',
        value: '',
      },
      important2: {
        label: 'What is most important to you?',
        value: '',
      },
      important3: {
        label: 'What is most important to you?',
        value: '',
      },
    },
    locationForm: {
      region: {
        label: 'Region',
        value: '',
      },
      drill: {
        label: 'Drill down to country',
        value: '',
      },
      drillCountry: {
        label: 'Drill down to county/state (optional)',
        value: '',
      },
      town: {
        label: 'Town (optional)',
        value: '',
      },
      searchRadius: {
        label: 'Search Radius (this area only, +N miles)',
        value: '',
      },
      reason: {
        label: 'Reason for facility',
        value: '',
      },
    },
    technicalFormCage: {
      power: {
        label: 'Total power required (kW)',
        value: '',
      },
      racks: {
        label: 'How many racks?',
        value: '',
      },
      height: {
        label: 'Height if known',
        value: '',
      },
      width: {
        label: 'Width if known',
        value: '',
      },
      powerFeeds: {
        label: 'What power feeds do you require',
        value: '',
      },
      security: {
        label: 'Do you expect to require additional security?',
        value: '',
      },
    },
    technicalFormRacks: {
      racks: {
        label: 'How many racks?',
        value: '',
      },
      powerPerRack: {
        label: 'KW Power per rack',
        value: '',
      },
      height: {
        label: 'Height if known',
        value: '',
      },
      width: {
        label: 'Width if known',
        value: '',
      },
      powerFeedsColo: {
        label: 'What power feeds do you require',
        value: '',
      },
    },
    technicalFormWholesale: {
      space: {
        label: 'Space sq-ft',
        value: '',
      },
      power: {
        label: 'Power (kW)',
        value: '',
      },
    },
    commercialsForm: {
      contractLength: {
        label: 'Contract Length',
        value: '',
      },
      currency: {
        label: 'Billing Currency',
        value: '',
      },
      terms: {
        label: 'Do you have terms and conditiond with any other suppliers?',
        value: '',
      },
      additionalServices: {
        label: 'Do you need additional services?',
        value: '',
      },
    },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [technicalFormPage, setTechnicalFormPage] = useState('Dedicated Cage');
  const [isEditMode, setIsEditMode] = useState(false);

  const [isCompletionModalVisible, setIsCompletionModalVisible] =
    useState(false);

  const formRef = useRef();

  useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
    document.body.style.margin = '0';
    return () => {
      document.body.style.backgroundColor = 'unset';
    };
  }, []);

  const resetForm = (setValues) => {
    setValues(initialValues);
    setIsEditMode(false);
    setTechnicalFormPage('Dedicated Cage');
  };

  const enableEditMode = (page) => {
    setIsEditMode(true);
    setCurrentPage(page);
  };

  const matchMaking = (values) => {
    matchMakingProvidersAction({
      ...values,
      typeOfServices: technicalFormPage,
    })
      .then(() => {
        setIsCompletionModalVisible(false);
        setCurrentPage(1);
        history.push('/match-making-result');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitFormData = (values) => {
    setIsCompletionModalVisible(true);
    matchMaking(values);
  };

  const createUserAndMatchMaking = (values, signUpValues) => {
    setIsCompletionModalVisible(true);

    createUser({
      ...signUpValues,
      role: 'role.buyer',
    })
      .then(() => {
        matchMaking(values);
      })
      .catch(() => {
        setIsCompletionModalVisible(false);
        setError({
          message: 'User already exists',
        });
      });
  };
  return (
    <Layout>
      <div className={classes.QuestionsFormPage}>
        <ProgressFlow
          currentPage={currentPage}
          pageNames={[
            'Business',
            'Location',
            'Technical',
            'Commercials',
            'Finish',
          ]}
        />
        <Formik
          onSubmit={submitFormData}
          initialValues={initialValues}
          validationSchema={ValidationSchema}
        >
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            values,
            touched,
            setValues,
            setTouched,
            setErrors,
            /* isValid, */
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {currentPage === 1 && (
                <BusinessForm
                  typeForm="businessForm"
                  values={values.businessForm}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  setCurrentPage={setCurrentPage}
                  isEditMode={isEditMode}
                  state={state}
                  setState={setState}
                  errors={errors}
                  touched={touched}
                />
              )}
              {currentPage === 2 && (
                <LocationForm
                  typeForm="locationForm"
                  values={values.locationForm}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  setCurrentPage={setCurrentPage}
                  isEditMode={isEditMode}
                  state={state}
                  setState={setState}
                  errors={errors}
                  touched={touched}
                />
              )}
              {currentPage === 3 && technicalFormPage === 'Dedicated Cage' && (
                <TechnicalFormCage
                  values={values.technicalFormCage}
                  typeForm="technicalFormCage"
                  allValues={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  technicalFormPage={technicalFormPage}
                  setTechnicalFormPage={setTechnicalFormPage}
                  isEditMode={isEditMode}
                  state={state}
                  setState={setState}
                  errors={errors}
                  touched={touched}
                />
              )}
              {currentPage === 3 && technicalFormPage === 'Colo-racks' && (
                <TechnicalFormRacks
                  values={values.technicalFormRacks}
                  typeForm="technicalFormRacks"
                  allValues={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  technicalFormPage={technicalFormPage}
                  setTechnicalFormPage={setTechnicalFormPage}
                  isEditMode={isEditMode}
                  state={state}
                  setState={setState}
                  errors={errors}
                  touched={touched}
                />
              )}
              {currentPage === 3 && technicalFormPage === 'Wholesale' && (
                <TechnicalFormWholesale
                  values={values.technicalFormWholesale}
                  typeForm="technicalFormWholesale"
                  allValues={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  technicalFormPage={technicalFormPage}
                  setTechnicalFormPage={setTechnicalFormPage}
                  isEditMode={isEditMode}
                  state={state}
                  setState={setState}
                  errors={errors}
                  touched={touched}
                />
              )}
              {currentPage === 4 && (
                <CommercialsForm
                  values={values.commercialsForm}
                  typeForm="commercialsForm"
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  isEditMode={isEditMode}
                  state={state}
                  setState={setState}
                  errors={errors}
                  touched={touched}
                />
              )}
              {currentPage === 5 && (
                <>
                  <FilledOutSections
                    name="Business"
                    state={state.businessForm}
                    onEdit={() => enableEditMode(1)}
                  />
                  <FilledOutSections
                    name="Location"
                    state={state.locationForm}
                    onEdit={() => enableEditMode(2)}
                  />
                  {technicalFormPage === 'Dedicated Cage' && (
                    <FilledOutSections
                      name="Technical - Dedicated Cage"
                      state={state.technicalFormCage}
                      onEdit={() => enableEditMode(3)}
                    />
                  )}
                  {technicalFormPage === 'Colo-racks' && (
                    <FilledOutSections
                      name="Technical - Colo-racks"
                      state={state.technicalFormRacks}
                      onEdit={() => enableEditMode(3)}
                    />
                  )}
                  {technicalFormPage === 'Wholesale' && (
                    <FilledOutSections
                      name="Technical - Wholesale"
                      state={state.technicalFormWholesale}
                      onEdit={() => enableEditMode(3)}
                    />
                  )}
                  <FilledOutSections
                    name="Commercials"
                    state={state.commercialsForm}
                    onEdit={() => enableEditMode(4)}
                  />
                  {!hasAuthData() && (
                    <div className={classes.details}>
                      <header className={classes.header}>
                        <h1>Details</h1>
                        <i>pen icon</i>
                      </header>
                      <div className={classes.container}>
                        <LoginForm
                          formRef={formRef}
                          createUserAndMatchMaking={createUserAndMatchMaking}
                          matchMakingValues={values}
                          error={error}
                        />
                      </div>
                    </div>
                  )}
                  <div className={classes.finishButtons}>
                    <button
                      type="button"
                      className={classes.reset}
                      onClick={() => {
                        setErrors(() => {});
                        setTouched(() => {});
                        resetForm(setValues);
                        setCurrentPage(1);
                      }}
                    >
                      Reset
                    </button>
                    {hasAuthData() ? (
                      <button type="submit" className={classes.submit}>
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={classes.submit}
                        onClick={() => formRef.current.handleSubmit()}
                      >
                        Signup to Submit
                      </button>
                    )}
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
      <MatchMakingFormCompletion
        show={isCompletionModalVisible}
        onHide={setIsCompletionModalVisible}
      />
    </Layout>
  );
};

export default connect(null, {
  matchMakingProvidersAction: providersActions.matchMakingProviders,
  createUser: userActions.createUser,
})(QuestionsFormPage);
