import React from 'react';

import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import classes from '../styles.module.scss';

const SecurityForm = ({ values, setFieldValue, typeForm }) => {
  return (
    <div className={classes.ContainerForm}>
      <div className={classes.header}>
        <h1>Security</h1>
      </div>
      <div className={classes.container}>
        <div className={classes.row}>
          <span>On-Site Security Personnel</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="onSiteSecurityPersonnel"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.onSiteSecurityPersonnel}
              onChange={() => {
                setFieldValue(`${typeForm}.onSiteSecurityPersonnel`, true);
              }}
            />
            <Form.Check
              name="onSiteSecurityPersonnel"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.onSiteSecurityPersonnel}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.onSiteSecurityPersonnel`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Mantrap</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasMantrap"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasMantrap}
              onChange={() => {
                setFieldValue(`${typeForm}.hasMantrap`, true);
              }}
            />
            <Form.Check
              name="hasMantrap"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasMantrap}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasMantrap`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Ballistic Rated Exterior Walls</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasBallisticRatedExteriorWalls"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasBallisticRatedExteriorWalls}
              onChange={() => {
                setFieldValue(
                  `${typeForm}.hasBallisticRatedExteriorWalls`,
                  true
                );
              }}
            />
            <Form.Check
              name="hasBallisticRatedExteriorWalls"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasBallisticRatedExteriorWalls}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(
                  `${typeForm}.hasBallisticRatedExteriorWalls`,
                  false
                );
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>24/7 Remote Monitoring</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasRemoteMonitoring"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasRemoteMonitoring}
              onChange={() => {
                setFieldValue(`${typeForm}.hasRemoteMonitoring`, true);
              }}
            />
            <Form.Check
              name="hasRemoteMonitoring"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasRemoteMonitoring}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasRemoteMonitoring`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Biometric Authentication</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasBiometricAuthentication"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasBiometricAuthentication}
              onChange={() => {
                setFieldValue(`${typeForm}.hasBiometricAuthentication`, true);
              }}
            />
            <Form.Check
              name="hasBiometricAuthentication"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasBiometricAuthentication}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasBiometricAuthentication`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Retina Scanners</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasRetinaScanners"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasRetinaScanners}
              onChange={() => {
                setFieldValue(`${typeForm}.hasRetinaScanners`, true);
              }}
            />
            <Form.Check
              name="hasRetinaScanners"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasRetinaScanners}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasRetinaScanners`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Keycard Access</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasKeycardAccess"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasKeycardAccess}
              onChange={() => {
                setFieldValue(`${typeForm}.hasKeycardAccess`, true);
              }}
            />
            <Form.Check
              name="hasKeycardAccess"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasKeycardAccess}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasKeycardAccess`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Dual Authentication</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasDualAuthentication"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasDualAuthentication}
              onChange={() => {
                setFieldValue(`${typeForm}.hasDualAuthentication`, true);
              }}
            />
            <Form.Check
              name="hasDualAuthentication"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasDualAuthentication}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasDualAuthentication`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>CCTV</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasCCTV"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasCCTV}
              onChange={() => {
                setFieldValue(`${typeForm}.hasCCTV`, true);
              }}
            />
            <Form.Check
              name="hasCCTV"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasCCTV}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasCCTV`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Perimeter Intrusion Detection</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasPerimeterIntrusionDetection"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasPerimeterIntrusionDetection}
              onChange={() => {
                setFieldValue(
                  `${typeForm}.hasPerimeterIntrusionDetection`,
                  true
                );
              }}
            />
            <Form.Check
              name="hasPerimeterIntrusionDetection"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasPerimeterIntrusionDetection}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(
                  `${typeForm}.hasPerimeterIntrusionDetection`,
                  false
                );
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Electric Fence</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasElectricFence"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasElectricFence}
              onChange={() => {
                setFieldValue(`${typeForm}.hasElectricFence`, true);
              }}
            />
            <Form.Check
              name="hasElectricFence"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasElectricFence}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasElectricFence`, false);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityForm;
