import React, { useCallback, useEffect, useRef, useState } from 'react';
/* import classnames from 'classnames'; */
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
/* import { formatRelative } from 'date-fns'; */

import Spinner from 'react-bootstrap/esm/Spinner';

import { actions as providersActions } from '../../resources/providers';

// eslint-disable-next-line import/no-named-as-default
import UserMenu from '../../components/Layout/Header/UserMenu';

import ProviderItem from '../../components/MatchMaking/ProviderItem';

import { hasAuthData } from '../../helpers/authStorage';

import classes from './styles.module.scss';

const libraries = ['places'];
const mapContainerStyle = {
  height: '100%',
  width: '100%',
};

const options = {
  zoomControl: true,
};

const DataCenterProviders = ({
  isFetchingItem,
  provider,
  getProviderAction,
}) => {
  const { id } = useParams();
  const logo = provider ? JSON.parse(provider.logoPath) : '';
  const [center, setCenter] = useState({
    lat: 33.75569808435381,
    lng: -84.39105320989381,
  });
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'en',
  });

  const selectedLocation = async (location) => {
    const results = await getGeocode({ address: location });
    const { lat, lng } = await getLatLng(results[0]);
    setCenter({ lat, lng });
  };

  const getLatLngAddress = async (a) => {
    try {
      const results = await getGeocode({ address: a });
      const { lat, lng } = await getLatLng(results[0]);
      setCenter({ lat, lng });
    } catch (error) {
      console.log(error);
    }
  };

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const fetchDataCenter = () => {
    getProviderAction(id);
  };

  useEffect(() => {
    fetchDataCenter(id);
  }, [id]);

  useEffect(() => {
    if (provider && isLoaded) {
      getLatLngAddress(provider.address);
    }
  }, [provider, isLoaded]);

  if (loadError) return 'Error';

  return (
    <div className={classes.DataCenterProviders}>
      <div className={classes.boxInner}>
        <div className={classes.header}>
          <div className={classes.container}>
            <div className={classes.headerInner}>
              <Link to="/">
                <div className={classes.logo} />
              </Link>
              {hasAuthData() ? (
                <UserMenu inProvidersPage />
              ) : (
                <div className={classes.loginContainer}>
                  <Link to="/login" className={classes.loginLink}>
                    Login
                  </Link>
                  <Link to="/signup">
                    <div className={classes.signupLink}>Sign Up</div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classes.providerItems}>
          {!isLoaded || isFetchingItem ? (
            <div className={classes.loader}>
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className={classes.container}>
              <ProviderItem
                logo={logo}
                dataCenterName={provider.dataCenterName}
                address={provider.address}
                selectedLocation={selectedLocation}
              />
            </div>
          )}
        </div>
      </div>
      {!isLoaded ? (
        <div className={classes.loader}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className={classes.map}>
          <GoogleMap
            id="map"
            mapContainerStyle={mapContainerStyle}
            zoom={5}
            center={center}
            options={options}
            onLoad={onMapLoad}
          >
            <Marker
              key={`${center.lat}-${center.lng}`}
              position={{ lat: center.lat, lng: center.lng }}
            />
          </GoogleMap>
        </div>
      )}
    </div>
  );
};

export default connect(
  (state) => {
    return {
      provider: state.providers.item,
      isFetching: state.providers.isFetching,
      isFetchingItem: state.providers.isFetchingItem,
    };
  },
  {
    fetchProvidersAction: providersActions.fetchProviders,
    getProviderAction: providersActions.getProvider,
  }
)(DataCenterProviders);
