import React, { useState } from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

const Buttons = ({
  buttonName1,
  buttonName2,
  setFieldValue,
  fieldName,
  isInvalid,
  selected,
}) => {
  const [active, setActive] = useState(selected);

  return (
    <div className={classes.buttonsFieldContainer} isInvalid={isInvalid}>
      <button
        className={classnames(
          classes.formButton,
          active === buttonName1 && classes.active
        )}
        type="button"
        onClick={() => {
          setActive(buttonName1);
          setFieldValue(fieldName, buttonName1);
        }}
      >
        {buttonName1}
      </button>
      <button
        className={classnames(
          classes.formButton,
          active === buttonName2 && classes.active
        )}
        type="button"
        onClick={() => {
          setActive(buttonName2);
          setFieldValue(fieldName, buttonName2);
        }}
      >
        {buttonName2}
      </button>
    </div>
  );
};

export default Buttons;
