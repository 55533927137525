import { createResource } from 'redux-rest-resource';

import { API_URL } from '../constants/main';
import { paginatedFetchReducer } from '../helpers/resourceReducers';
import { paginatedFetchMatchMakingReducer } from '../helpers/paginateFetchMatchMakingReducers';

export const { types, actions, rootReducer } = createResource({
  name: 'provider',
  url: `${API_URL}/providers/:id`,

  actions: {
    fetch: {
      url: `${API_URL}/providers`,
      reduce: paginatedFetchReducer,
    },
    search: {
      url: `${API_URL}/providers/search`,
      reduce: paginatedFetchReducer,
      isArray: true,
    },
    matchMaking: {
      method: 'POST',
      gerundName: 'matchMaking',
      url: `${API_URL}/providers/match-making`,
      reduce: paginatedFetchMatchMakingReducer,
      isArray: true,
    },
  },
});
