const userAllowedActions = () => {
  const authData = JSON.parse(localStorage.getItem('authData'));
  if (authData) {
    const { accessToken } = authData;

    const accessTokenToArr = accessToken.slice(4).split('.');

    const decodedToken = JSON.parse(atob(accessTokenToArr[1]));

    return {
      ...decodedToken,
      allowedAction: [decodedToken.allowedAction],
    };
  }

  return null;
};

export default userAllowedActions;
