import React from 'react';

import BootstrapForm from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';

import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().min(8, 'Too Short!').required('Please enter password'),
  fullName: Yup.string().required('Please enter full name'),
});

const LoginForm = ({
  formRef,
  createUserAndMatchMaking,
  matchMakingValues,
  error,
}) => {
  return (
    <Formik
      innerRef={formRef}
      initialValues={{ email: '', fullName: '', password: '' }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        createUserAndMatchMaking(matchMakingValues, values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        // isSubmitting,
      }) => (
        <form noValidate onSubmit={handleSubmit} className={classes.form}>
          {error && <div className={classes.Error}>{error.message}</div>}
          <div className={classes.container}>
            <div className={classes.inputGroup}>
              <BootstrapForm.Label className={classes.label}>
                Email Address
              </BootstrapForm.Label>
              <BootstrapForm.Control
                className={classes.input}
                isValid={touched.email && !errors.email}
                type="email"
                placeholder=""
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <span className={classes.errorMessage}>
                {errors.email && touched.email && errors.email}
              </span>
            </div>
            <div className={classes.inputGroup}>
              <BootstrapForm.Label className={classes.label}>
                Name
              </BootstrapForm.Label>
              <BootstrapForm.Control
                className={classes.input}
                type="text"
                isValid={touched.fullName && !errors.fullName}
                placeholder=""
                name="fullName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.fullName}
              />
              <span className={classes.errorMessage}>
                {errors.fullName && touched.fullName && errors.fullName}
              </span>
            </div>
            <div className={classes.inputGroup}>
              <BootstrapForm.Label className={classes.label}>
                Password
              </BootstrapForm.Label>
              <BootstrapForm.Control
                className={classes.input}
                isValid={touched.password && !errors.password}
                type="password"
                placeholder=""
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              <span className={classes.errorMessage}>
                {errors.password && touched.password && errors.password}
              </span>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
