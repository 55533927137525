import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import axios from 'axios';
import classnames from 'classnames';
/* import Spinner from 'react-bootstrap/Spinner'; */

import logo from '../../../assets/images/Dashboard/logoBig.png';

import classes from './styles.module.scss';
import Account from '../../../components/Dashboard/Account';
import { API_URL } from '../../../constants/main';

const SettingsPage = ({ user }) => {
  const [page, setPage] = useState('account');
  const [company, setCompany] = useState(null);

  const getCompanyOnPage = (id) => {
    axios
      .get(`${API_URL}/company`, {
        params: {
          userId: id,
        },
      })
      .then((res) => {
        setCompany(res.data.company);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (user && user.id) {
      getCompanyOnPage(user.id);
    }
  }, [user]);
  return (
    <div className={classes.SettingsPage}>
      <div className={classes.title}>Account Setting</div>
      <div className={classes.container}>
        <div className={classes.sidebar}>
          <div className={classes.logoBox}>
            <div className={classes.logo}>
              <img src={logo} alt="" />
            </div>
            <div className={classes.name}>{user ? user.fullName : ''}</div>
          </div>
          <div className={classes.items}>
            <div
              className={classnames(
                classes.item,
                page === 'account' && classes.active
              )}
              onClick={() => setPage('account')}
            >
              Account
            </div>
            <div
              className={classnames(
                classes.item,
                page === 'password' && classes.active
              )}
              onClick={() => setPage('password')}
            >
              Password
            </div>
            <div
              className={classnames(
                classes.item,
                page === 'security&Privacy' && classes.active
              )}
              onClick={() => setPage('security&Privacy')}
            >
              Security & Privacy
            </div>
            <div
              className={classnames(
                classes.item,
                page === 'datacenterSetting' && classes.active
              )}
              onClick={() => setPage('datacenterSetting')}
            >
              Datacenter Setting
            </div>
            <div
              className={classnames(
                classes.item,
                page === 'notification' && classes.active
              )}
              onClick={() => setPage('notification')}
            >
              Notification
            </div>
          </div>
        </div>
        <div className={classes.main}>
          {page === 'account' && company && (
            <Account user={user} company={company} />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    user: state.users.item,
  };
}, null)(SettingsPage);
