import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';

import classes from './styles.module.scss';

export default function FormField({ label, value, onChange, onBlur, name }) {
  const [isShowPassword, setIsShowPassword] = useState(false);
  return (
    <Form.Group className={classes.FormField}>
      <Form.Label className={classes.formLabel}>{label}</Form.Label>
      <div className={classes.formFieldContainer}>
        <Form.Control
          className={classes.formControl}
          type={isShowPassword ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
        />
        <div
          className={classes.eyeContainer}
          onClick={() => setIsShowPassword(!isShowPassword)}
        />
      </div>
    </Form.Group>
  );
}
