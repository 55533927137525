import React from 'react';

import Modal from 'react-bootstrap/Modal';
import classes from './styles.module.scss';

export default function SelectAccountModal({ show, handleClose, setRole }) {
  const selectedRole = (role) => {
    setRole(role);
    handleClose();
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName={classes.SelectAccountModal}
      dialogClassName={classes.dialog}
    >
      <i
        className={classes.closeIcon}
        tabIndex={0}
        role="button"
        onClick={handleClose}
      >
        Close
      </i>
      <div className={classes.message}>
        <p className={classes.title}>Choose your account</p>
        <p className={classes.subtitle}>Connect your account</p>
      </div>
      <div className={classes.buttonsContainer}>
        <button type="button" onClick={() => selectedRole('role.buyer')}>
          Sign up as Buyer
        </button>
        <span>OR</span>
        <button type="button" onClick={() => selectedRole('role.seller')}>
          Sign up as Seller
        </button>
      </div>
    </Modal>
  );
}
