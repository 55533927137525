/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { actions } from '../../../resources/users';
import classes from './styles.module.scss';
import { getAuthData } from '../../../helpers/authStorage';
import { STATIC_URL } from '../../../constants/main';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    style={{ textDecoration: 'none' }}
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

export function UserMenu({ logout, user, getUser, inProvidersPage }) {
  const history = useHistory();

  useEffect(() => {
    getUser(getAuthData().userId);
  }, []);
  return (
    <div className={classes.UserMenu}>
      <div className={classes.notification}>
        <div className={classes.number}>
          <span>9</span>
        </div>
      </div>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <div className={classes.user}>
            <div
              className={classes.avatar}
              style={{
                backgroundImage:
                  user && user.avatarPath
                    ? `url(${STATIC_URL}${user.avatarPath.replace(/\\/g, '/')})`
                    : '',
              }}
            />
            <span
              className={classnames(
                classes.triangleHide,
                inProvidersPage && classes.triangle
              )}
            />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} style={{ height: 82 }}>
          <Dropdown.Item
            eventKey="1"
            onClick={() => history.push('/dashboard/settings')}
          >
            Profile Setting
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="2"
            onClick={() => {
              logout();
              history.push('/login');
            }}
          >
            Sign Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default connect((state) => ({ user: state.users.item }), {
  logout: actions.logoutUser,
  getUser: actions.getUser,
})(UserMenu);
