import React from 'react';
import Form from 'react-bootstrap/Form';
import classes from './styles.module.scss';

const InputForm = ({ state, onChange }) => {
  return (
    <div className={classes.inputForm}>
      <div className={classes.title}>{state.label}</div>
      <Form.Control
        className={classes.textInput}
        value={state.value}
        placeholder={state.placeholder}
        onChange={onChange}
        name={state.name}
      />
    </div>
  );
};

export default InputForm;
