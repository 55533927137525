import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import classnames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { actions as userActions } from '../../../resources/users';

import firstAllowedRoute from '../../../helpers/firstAllowedRoute';

import AuthHeader from '../../../components/Layout/AuthHeader';
import FormField from '../../../components/FormComponents/FormFieldWithEye';

import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Please enter password'),
});

function SignUpPage({ loginUser }) {
  const [error, setError] = useState(null);

  const history = useHistory();

  return (
    <div className={classes.LoginPage}>
      <AuthHeader />
      <div className={classes.leftColumn} />
      <div className={classes.rightColumn}>
        <div className={classes.wellcomeBlock}>
          <h1>Login to Coolbytz</h1>
          <p>Connect your account</p>
        </div>
        <div className={classes.signUpForm}>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {
              loginUser(values)
                .then(() => history.push(firstAllowedRoute()))
                .catch(() => {
                  setError({
                    message: 'Email or password is invalid',
                  });
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                {error && <div className={classes.Error}>{error.message}</div>}
                <div className={classes.signInputWrapper}>
                  <h5>Email Address</h5>
                  <div
                    className={classnames(
                      classes.signInput,
                      errors.email &&
                        touched.email &&
                        errors.email &&
                        classes.isInvalid
                    )}
                  >
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </div>
                  <span className={classes.errorMessage}>
                    {errors.email && touched.email && errors.email}
                  </span>
                </div>
                <div className={classes.signInputWrapper}>
                  <FormField
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Password"
                    name="password"
                  />
                  <span className={classes.errorMessage}>
                    {errors.password && touched.password && errors.password}
                  </span>
                </div>
                <div
                  className={classnames(
                    classes.signAction,
                    errors.agree &&
                      touched.agree &&
                      errors.agree &&
                      classes.isInvalid
                  )}
                />
                <span
                  className={classnames(
                    classes.errorMessage,
                    errors.agree &&
                      touched.agree &&
                      errors.agree &&
                      classes.lastItem
                  )}
                >
                  {errors.agree && touched.agree && errors.agree}
                </span>
                <button
                  type="submit"
                  className={classes.btnSubmit}
                  disabled={isSubmitting}
                >
                  Login
                </button>
                <div className={classes.linkBox}>
                  <span className={classes.span}>New to Coolbytz?</span>
                  <Link to="/signup" className={classes.createAccountBtn}>
                    Create an account.
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default connect(null, {
  loginUser: userActions.loginUser,
})(SignUpPage);
