export const paginatedFetchReducer = (state, action) => {
  if (action.payload) {
    return {
      ...state,
      items: action.payload.body.data,
      pageIndex: action.payload.body.pageIndex,
      totalItemsCount: +action.payload.body.totalItemsCount,
    };
  }
  return {
    ...state,
  };
};
