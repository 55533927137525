/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ConfirmModal from '../../../modals/ConfirmModal';
import UpdateProviderModal from '../../../modals/CreateDataCenterModal';

import classes from './styles.module.scss';

const ProviderItem = ({ item, deleteProviderAction, fetchProvidersOnPage }) => {
  const history = useHistory();
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const openUpdateModalForm = (e) => {
    e.stopPropagation();
    setOpenUpdateModal(true);
  };

  const openConfirmModal = (e) => {
    e.stopPropagation();
    setOpenRemoveModal(true);
  };

  const deleteProvider = (id) => {
    deleteProviderAction(id).then(() => {
      setOpenRemoveModal(false);
      fetchProvidersOnPage(0);
    });
  };
  return (
    <>
      <tr className={classes.itemContainer} key={item.id}>
        <td className={classes.value}>{item.dataCenterName}</td>
        <td className={classes.value}>{item.title}</td>
        <td className={classes.value}>{item.email}</td>
        <td className={classes.value}>{item.address}</td>
        <td className={classes.value}>{item.website}</td>
        <td className={classes.value}>{item.phoneNo}</td>
        <td
          className={classes.eyeContainer}
          onClick={() => history.push(`/details/${item.id}`)}
        >
          1
        </td>
        <td className={classes.pencilContainer} onClick={openUpdateModalForm}>
          1
        </td>
        <td className={classes.basketContainer} onClick={openConfirmModal}>
          1
        </td>
      </tr>
      <UpdateProviderModal
        provider={item}
        show={openUpdateModal}
        onHide={setOpenUpdateModal}
        fetchProvidersOnPage={fetchProvidersOnPage}
      />
      <ConfirmModal
        id={item.id}
        show={openRemoveModal}
        handleClose={setOpenRemoveModal}
        onConfirm={deleteProvider}
        isDeleteConfirm
      />
    </>
  );
};

export default ProviderItem;
