import React from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

export default function FilledOutSections({ name, state, onEdit }) {
  return (
    <div className={classes.FilledOutSections}>
      <header className={classes.header}>
        <h1>{name}</h1>
        <i onClick={onEdit}>pen icon</i>
      </header>
      <div className={classes.container}>
        {Object.keys(state).map((key) => (
          <div
            className={classnames(
              classes.section,
              key === 'powerFeedsColo' && classes.powerFeeds
            )}
            key={key}
          >
            <span className={classes.question}>{state[key].label}</span>
            <span className={classes.answer}>{state[key].value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
