import React from 'react';

import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import classes from '../styles.module.scss';

const MechanicalState = {
  mechanicalTierEquivalent: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4', 'Tier 5'],
  crahCRACRedundancy: ['N+1', 'N+2', 'N+3'],
  chillerRedundancy: ['N+1', 'N+2', 'N+3'],
  cabinetLayout: ['Hot Aisle', 'Cold Aisle', 'None'],
};

const MechanicalForm = ({
  values,
  setFieldValue,
  setFieldTouched,
  typeForm,
  errors,
  touched,
}) => {
  return (
    <div className={classes.ContainerForm}>
      <div className={classes.header}>
        <h1>Mechanical</h1>
      </div>
      <div className={classes.container}>
        <div className={classes.row}>
          <span>Mechanical Tier Equivalent</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.mechanicalTierEquivalent`,
                  event.target.value
                );
              }}
              onBlur={() =>
                setFieldTouched(`${typeForm}.mechanicalTierEquivalent`)
              }
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].mechanicalTierEquivalent &&
                touched[typeForm] &&
                touched[typeForm].mechanicalTierEquivalent
              }
              name="mechanicalTierEquivalent"
            >
              <option selected disabled>
                Please Select
              </option>
              {MechanicalState.mechanicalTierEquivalent.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.mechanicalTierEquivalent === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].mechanicalTierEquivalent ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].mechanicalTierEquivalent}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>CRAH/CRAC Redundancy</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.crahCRACRedundancy`,
                  event.target.value
                );
              }}
              onBlur={() => setFieldTouched(`${typeForm}.crahCRACRedundancy`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].crahCRACRedundancy &&
                touched[typeForm] &&
                touched[typeForm].crahCRACRedundancy
              }
              name="crahCRACRedundancy"
            >
              <option selected disabled>
                Please Select
              </option>
              {MechanicalState.crahCRACRedundancy.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.crahCRACRedundancy === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].crahCRACRedundancy ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].crahCRACRedundancy}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Chiller Redundancy</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.chillerRedundancy`,
                  event.target.value
                );
              }}
              onBlur={() => setFieldTouched(`${typeForm}.chillerRedundancy`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].chillerRedundancy &&
                touched[typeForm] &&
                touched[typeForm].chillerRedundancy
              }
              name="chillerRedundancy"
            >
              <option selected disabled>
                Please Select
              </option>
              {MechanicalState.chillerRedundancy.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.chillerRedundancy === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].chillerRedundancy ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].chillerRedundancy}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Cabinet Layout</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.cabinetLayout`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.cabinetLayout`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].cabinetLayout &&
                touched[typeForm] &&
                touched[typeForm].cabinetLayout
              }
              name="cabinetLayout"
            >
              <option selected disabled>
                Please Select
              </option>
              {MechanicalState.cabinetLayout.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.cabinetLayout === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].cabinetLayout ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].cabinetLayout}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Concurrently Maintainable Mechanical</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasConcurrentlyMaintainableMechanical"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasConcurrentlyMaintainableMechanical}
              onChange={() => {
                setFieldValue(
                  `${typeForm}.hasConcurrentlyMaintainableMechanical`,
                  true
                );
              }}
            />
            <Form.Check
              name="hasConcurrentlyMaintainableMechanical"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasConcurrentlyMaintainableMechanical}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(
                  `${typeForm}.hasConcurrentlyMaintainableMechanical`,
                  false
                );
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Min Cooling Capacity Per Rack (kW)</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.minCoolingCapacityPerRack}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.minCoolingCapacityPerRack`,
                  event.target.value
                );
              }}
              onBlur={() =>
                setFieldTouched(`${typeForm}.minCoolingCapacityPerRack`)
              }
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].minCoolingCapacityPerRack &&
                touched[typeForm] &&
                touched[typeForm].minCoolingCapacityPerRack
              }
              name="minCoolingCapacityPerRack"
            />
            {errors[typeForm] && errors[typeForm].minCoolingCapacityPerRack ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].minCoolingCapacityPerRack}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MechanicalForm;
