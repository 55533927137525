import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';

import classnames from 'classnames';

import Buttons from './Buttons';

import classes from './styles.module.scss';

const BusinessState = {
  purpose: ['Production', 'Disaster Recovery', 'Test', 'Network Access Point'],
  timelines: [
    'Short-term 0-3 months',
    'Medium-term 3-9 month',
    'Long-term >9 months',
  ],
  sector: [
    'Finance',
    'Technology',
    'Education',
    'Manufacturing',
    'IT Services',
    'Engineering',
    'Software',
    'Insurance',
    'Transportation',
    'Non-profit',
    'Hospitality',
  ],
  reason: [
    'Migration from another site',
    'Increase capacity',
    'New Business Requirement',
    'Budgeting Purposes',
  ],
  important: ['Cost', 'Tier Rating'],
  important2: ['Cost', 'Connectivity'],
  important3: ['Cost', 'Supplier Performance'],
};

const BusinessForm = ({
  typeForm,
  setFieldValue,
  setFieldTouched,
  values,
  setCurrentPage,
  isEditMode,
  state,
  setState,
  errors,
  touched,
  /* setErrors, */
}) => {
  const [oldState, setOldState] = useState({});
  const changeState = () => {
    setState((prev) => {
      return {
        ...prev,
        businessForm: {
          purpose: {
            ...state.businessForm.purpose,
            value: values.purpose,
          },
          timelines: {
            ...state.businessForm.timelines,
            value: values.timelines,
          },
          sector: {
            ...state.businessForm.sector,
            value: values.sector,
          },
          reason: {
            ...state.businessForm.reason,
            value: values.reason,
          },
          important: {
            ...state.businessForm.important,
            value: values.important,
          },
          important2: {
            ...state.businessForm.important2,
            value: values.important2,
          },
          important3: {
            ...state.businessForm.important3,
            value: values.important3,
          },
        },
      };
    });
  };

  useEffect(() => {
    setOldState({ ...values });
  }, []);

  const isValid = async () => {
    const result = await Promise.all([
      setFieldTouched(`${typeForm}.purpose`, true, true),
      setFieldTouched(`${typeForm}.timelines`, true, true),
      setFieldTouched(`${typeForm}.sector`, true, true),
      setFieldTouched(`${typeForm}.reason`, true, true),
      setFieldTouched(`${typeForm}.important`, true, true),
      setFieldTouched(`${typeForm}.important2`, true, true),
      setFieldTouched(`${typeForm}.important3`, true, true),
    ]);
    const firstItem = result[0];
    if (firstItem.businessForm) {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.Form}>
      <div className={classes.container}>
        <div className={classes.row}>
          <span>Purpose of the datacenter?</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.purpose`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.purpose`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].purpose &&
                touched[typeForm] &&
                touched[typeForm].purpose
              }
              name="purpose"
            >
              <option selected disabled>
                Please Select
              </option>
              {BusinessState.purpose.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.purpose === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].purpose ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].purpose}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Timelines</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.timelines`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.timelines`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].timelines &&
                touched[typeForm] &&
                touched[typeForm].timelines
              }
              name="timelines"
            >
              <option selected disabled>
                Please Select
              </option>
              {BusinessState.timelines.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.timelines === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].timelines ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].timelines}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>What sector do you operate in?</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.sector`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.sector`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].sector &&
                touched[typeForm] &&
                touched[typeForm].sector
              }
              name="sector"
            >
              <option selected disabled>
                Please Select
              </option>
              {BusinessState.sector.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.sector === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].sector ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].sector}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Reason for facility</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.reason`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.reason`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].reason &&
                touched[typeForm] &&
                touched[typeForm].reason
              }
              name="reason"
            >
              <option selected disabled>
                Please Select
              </option>
              {BusinessState.reason.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.reason === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].reason ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].reason}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>What is most important to you?</span>
          <div>
            <Buttons
              buttonName1="Cost"
              buttonName2="Tier Rating"
              fieldName={`${typeForm}.important`}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              selected={values.important}
            />
            {errors[typeForm] &&
            errors[typeForm].important &&
            touched[typeForm] &&
            touched[typeForm].important ? (
              <div className={classes.errBox}>{errors[typeForm].important}</div>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>What is most important to you?</span>
          <div>
            <Buttons
              buttonName1="Cost"
              buttonName2="Connectivity"
              fieldName={`${typeForm}.important2`}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              selected={values.important2}
            />
            {errors[typeForm] &&
            errors[typeForm].important2 &&
            touched[typeForm] &&
            touched[typeForm].important2 ? (
              <div className={classes.errBox}>
                {errors[typeForm].important2}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>What is most important to you?</span>
          <div>
            <Buttons
              buttonName1="Cost"
              buttonName2="Supplier Performance"
              fieldName={`${typeForm}.important3`}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              selected={values.important3}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].important3 &&
                touched[typeForm] &&
                touched[typeForm].important3
              }
            />
            {errors[typeForm] &&
            errors[typeForm].important3 &&
            touched[typeForm] &&
            touched[typeForm].important3 ? (
              <div className={classes.errBox}>
                {errors[typeForm].important3}
              </div>
            ) : null}
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          {isEditMode && (
            <button
              className={classes.formButton}
              type="button"
              onClick={() => {
                setFieldValue(`${typeForm}`, oldState);
                setCurrentPage(() => {
                  return 5;
                });
              }}
            >
              Cancel
            </button>
          )}
          <button
            className={classes.formButton}
            type="button"
            onClick={async () => {
              const res = await isValid();
              if (res) {
                setCurrentPage((prevState) => {
                  if (prevState === 4 && !isEditMode) {
                    return prevState;
                  }
                  if (isEditMode) {
                    changeState();
                    return 5;
                  }
                  changeState();
                  return prevState + 1;
                });
              }
            }}
          >
            {isEditMode ? 'Save' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessForm;
