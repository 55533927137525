import React, { useEffect } from 'react';
/* import FormComponentInModalForm from '../FormComponentInModalForm'; */
import CertificationsForm from './Forms/CertificationsForm';
import ElectricalForm from './Forms/ElectricalForm';
import FeaturesForm from './Forms/FeaturesForm';
import GeneralForm from './Forms/GeneralForm';
import MechanicalForm from './Forms/MechanicalForm';
import SecurityForm from './Forms/SecurityForm';

import classes from './styles.module.scss';

const MultipleForms = ({
  values,
  setFieldValue,
  setFieldTouched,
  setCurrentPage,
  errors,
  touched,
  isCancel,
  setErrors,
  resetForm,
}) => {
  const generateArr = (state, typeForm) => {
    return Object.keys(state).map((field) =>
      setFieldTouched(`${typeForm}.${field}`, true, true)
    );
  };

  const isValid = async () => {
    const result = await Promise.all([
      ...generateArr(values.generalForm, 'generalForm'),
      ...generateArr(values.electricalForm, 'electricalForm'),
      ...generateArr(values.mechanicalForm, 'mechanicalForm'),
    ]);
    const firstItem = result[0];

    if (
      firstItem.generalForm ||
      firstItem.electricalForm ||
      firstItem.mechanicalForm
    ) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (isCancel) {
      resetForm(setErrors);
    }
  }, [isCancel]);
  return (
    <div className={classes.MultipleForms}>
      <GeneralForm
        typeForm="generalForm"
        values={values.generalForm}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        errors={errors}
        touched={touched}
      />
      <SecurityForm
        typeForm="securityForm"
        values={values.securityForm}
        setFieldValue={setFieldValue}
      />
      <ElectricalForm
        typeForm="electricalForm"
        values={values.electricalForm}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        errors={errors}
        touched={touched}
      />
      <MechanicalForm
        typeForm="mechanicalForm"
        values={values.mechanicalForm}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        errors={errors}
        touched={touched}
      />
      <CertificationsForm
        typeForm="certificationsForm"
        values={values.certificationsForm}
        setFieldValue={setFieldValue}
      />
      <FeaturesForm
        typeForm="featuresForm"
        values={values.featuresForm}
        setFieldValue={setFieldValue}
      />

      <div className={classes.buttonsOuterContainer}>
        <div className={classes.buttonsInnerContainer}>
          <button type="button" onClick={() => setCurrentPage(2)}>
            Back
          </button>
          <button
            type="button"
            onClick={async () => {
              const res = await isValid();
              if (res) {
                setCurrentPage(4);
              }
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default MultipleForms;
