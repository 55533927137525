import React from 'react';

import PhoneInput from 'react-phone-number-input/input';
import classnames from 'classnames';
import 'react-phone-number-input/style.css';
import classes from './styles.module.scss';

export default function PhoneField(props) {
  const {
    field: { name, value },
    form: { handleBlur, setFieldValue },
    onChange,
    isInvalid,
  } = props;

  const onValueChange = (phoneNumber = '') => {
    setFieldValue(name, phoneNumber);

    if (onChange !== null) {
      onChange(phoneNumber);
    }
  };

  const handleInputBlur = (e) => {
    handleBlur(e);
  };

  return (
    <div className={classes.PhoneField}>
      <PhoneInput
        placeholder="Type Phone No"
        name={name}
        value={value}
        onChange={onValueChange}
        onBlur={handleInputBlur}
        className={classnames(
          classes.phoneContainer,
          isInvalid && classes.isInvalid
        )}
      />
    </div>
  );
}
