/* eslint-disable array-callback-return */
import React from 'react';

import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import classes from './styles.module.scss';

function deleteFormValues(state) {
  const newState = {};
  Object.keys(state).map((key) => {
    newState[key] = '';
  });
  return newState;
}

const TypeRadios = ({
  technicalFormPage,
  setTechnicalFormPage,
  setFieldValue,
  allValues,
}) => {
  return (
    <div className={classes.TypeRadiosContainer}>
      <span>Type of services</span>
      <div className={classes.typeRadios}>
        <Form.Check
          name="typeRadio"
          bsPrefix={classnames('form-check', classes.radio)}
          bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
          type="radio"
          label="Dedicated Cage"
          checked={technicalFormPage === 'Dedicated Cage'}
          onChange={() => {
            setTechnicalFormPage('Dedicated Cage');
            setFieldValue(
              'technicalFormRacks',
              deleteFormValues(allValues.technicalFormRacks)
            );
            setFieldValue(
              'technicalFormWholesale',
              deleteFormValues(allValues.technicalFormWholesale)
            );
          }}
        />
        <Form.Check
          name="typeRadio"
          bsPrefix={classnames('form-check', classes.radio)}
          bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
          type="radio"
          label="Colo-racks"
          checked={technicalFormPage === 'Colo-racks'}
          onChange={() => {
            setTechnicalFormPage('Colo-racks');
            setFieldValue(
              'technicalFormCage',
              deleteFormValues(allValues.technicalFormCage)
            );
            setFieldValue(
              'technicalFormWholesale',
              deleteFormValues(allValues.technicalFormWholesale)
            );
          }}
        />
        <Form.Check
          name="typeRadio"
          bsPrefix={classnames('form-check', classes.radio)}
          bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
          type="radio"
          label="Wholesale"
          checked={technicalFormPage === 'Wholesale'}
          onChange={() => {
            setTechnicalFormPage('Wholesale');
            setFieldValue(
              'technicalFormCage',
              deleteFormValues(allValues.technicalFormCage)
            );
            setFieldValue(
              'technicalFormRacks',
              deleteFormValues(allValues.technicalFormRacks)
            );
          }}
        />
      </div>
    </div>
  );
};

export default TypeRadios;
