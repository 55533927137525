import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DashboardContainer from '../containers/Dashboard';

import YourDataCenterList from '../pages/Dashboard/YourDataCenter';
import Settings from '../pages/Dashboard/Settings';
import userAllowedActions from '../helpers/userAllowedActions';
import { ROLE_ACTIONS } from '../constants/main';

const Routes = (props) => {
  const { match } = props;
  return (
    <DashboardContainer {...props}>
      {userAllowedActions().allowedAction.includes(ROLE_ACTIONS.SELLER) && (
        <Switch>
          <Route
            exact
            path={`${match.url}/your-data-centers`}
            component={YourDataCenterList}
          />
          <Route exact path={`${match.url}/settings`} component={Settings} />
        </Switch>
      )}
    </DashboardContainer>
  );
};

export default Routes;
