import React from 'react';

import Sidebar from '../../components/Sidebar';
import Header from '../../components/HeaderDashboard';

import classes from './styles.module.scss';

export default function Dashboard(props) {
  const { children } = props;
  return (
    <div className={classes.Dashboard}>
      <Sidebar {...props} />
      <div className={classes.container}>
        <Header inDashboard />
        {children}
      </div>
    </div>
  );
}
