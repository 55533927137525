import React from 'react';

import classes from './styles.module.scss';
import logo from '../../../assets/images/logo.svg';

export default function AuthHeader() {
  return (
    <header className={classes.AuthHeader}>
      <img src={logo} alt="Logo" />
    </header>
  );
}
