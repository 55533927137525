/* eslint-disable no-nested-ternary */
import { Field } from 'formik';
import React, { useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import ReactFileReader from 'react-file-reader';
import { isValidPhoneNumber } from 'react-phone-number-input';

import PhoneField from '../../FormComponents/PhoneField';

import classes from './styles.module.scss';

const validationPhone = (value) => {
  let errorMessage;
  if (!isValidPhoneNumber(value)) {
    errorMessage = value ? 'Invalid phone number' : 'This field is required!';
  }
  return errorMessage;
};

const DetailsForm = ({
  typeForm,
  onCancel,
  setFieldValue,
  setFieldTouched,
  setErrors,
  handleBlur,
  handleChange,
  values,
  setCurrentPage,
  isCancel,
  resetForm,
  errors,
  touched,
}) => {
  const getFileFromUploader = (files) => {
    setFieldValue(`${typeForm}.logoPath`, files[0]);
  };

  const generateArr = (state, type) => {
    return Object.keys(state).map((field) =>
      setFieldTouched(`${type}.${field}`, true, true)
    );
  };

  const isValid = async () => {
    const result = await Promise.all([...generateArr(values, typeForm)]);
    const firstItem = result[0];
    if (firstItem.detailsForm) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (isCancel) {
      resetForm(setErrors);
    }
  }, [isCancel]);
  return (
    <div className={classes.DetailsForm}>
      <div className={classes.container}>
        <div className={classes.row}>
          <span>Name</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.name}
              onChange={(event) => {
                setFieldValue(`${typeForm}.name`, event.target.value);
              }}
              placeholder="Type Name"
              onBlur={() => setFieldTouched(`${typeForm}.name`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].name &&
                touched[typeForm] &&
                touched[typeForm].name
              }
              name="name"
            />
            {errors[typeForm] && errors[typeForm].name ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].name}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Data Center Name</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.dataCenterName}
              onChange={(event) => {
                setFieldValue(`${typeForm}.dataCenterName`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.dataCenterName`)}
              placeholder="Type Data Center Name"
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].dataCenterName &&
                touched[typeForm] &&
                touched[typeForm].dataCenterName
              }
              name="dataCenterName"
            />
            {errors[typeForm] && errors[typeForm].dataCenterName ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].dataCenterName}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Upload Logo</span>
          <div>
            <ReactFileReader
              handleFiles={(files) => getFileFromUploader(files)}
              multipleFiles={false}
              fileTypes={['.jpg', '.png']}
            >
              <div
                className={classes.fileUploader}
                onClick={() => setFieldTouched(`${typeForm}.logoPath`)}
              >
                <div className={classes.fileDetails}>
                  <span>
                    {values.logoPath.name
                      ? values.logoPath.name
                      : values.logoPath
                      ? values.logoPath
                      : 'Choose File (.jpg, .png)'}
                  </span>
                </div>
                <button type="button" className={classes.uploadButton}>
                  Upload
                </button>
              </div>
            </ReactFileReader>
            {errors[typeForm] &&
            errors[typeForm].logoPath &&
            touched[typeForm] &&
            touched[typeForm].logoPath ? (
              <div className={classes.errBox}>{errors[typeForm].logoPath}</div>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Title</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.title}
              onChange={(event) => {
                setFieldValue(`${typeForm}.title`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.title`)}
              placeholder="Type Title"
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].title &&
                touched[typeForm] &&
                touched[typeForm].title
              }
              name="title"
            />
            {errors[typeForm] && errors[typeForm].title ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].title}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Email</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.email}
              onChange={(event) => {
                setFieldValue(`${typeForm}.email`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.email`)}
              placeholder="Type Email"
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].email &&
                touched[typeForm] &&
                touched[typeForm].email
              }
              name="email"
            />
            {errors[typeForm] && errors[typeForm].email ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].email}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Address</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.address}
              onChange={(event) => {
                setFieldValue(`${typeForm}.address`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.address`)}
              placeholder="Type Address"
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].address &&
                touched[typeForm] &&
                touched[typeForm].address
              }
              name="address"
            />
            {errors[typeForm] && errors[typeForm].address ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].address}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Website</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.website}
              onChange={(event) => {
                setFieldValue(`${typeForm}.website`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.website`)}
              placeholder="Enter Website URL"
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].website &&
                touched[typeForm] &&
                touched[typeForm].website
              }
              name="website"
            />
            {errors[typeForm] && errors[typeForm].website ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].website}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Phone No</span>
          <div>
            <Field
              type="text"
              name={`${typeForm}.phoneNo`}
              onChange={handleChange(`${typeForm}.phoneNo`)}
              onBlur={handleBlur(`${typeForm}.phoneNo`)}
              component={PhoneField}
              validate={validationPhone}
              isInvalid={
                !!(
                  errors[typeForm] &&
                  errors[typeForm].phoneNo &&
                  touched[typeForm] &&
                  touched[typeForm].phoneNo
                )
              }
            />
            {errors[typeForm] &&
            errors[typeForm].phoneNo &&
            touched[typeForm] &&
            touched[typeForm].phoneNo ? (
              <div className={classes.errBox}>{errors[typeForm].phoneNo}</div>
            ) : null}
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          <button
            className={classes.formButton}
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className={classes.formButton}
            type="button"
            onClick={async () => {
              const res = await isValid();
              if (res) {
                setCurrentPage((prevState) => prevState + 1);
              }
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailsForm;
