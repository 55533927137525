import React /* , { useEffect } */ from 'react';

import { connect } from 'react-redux';

import { actions as providersActions } from '../../resources/providers';

import Layout from '../../containers/Layout';

import MatchMakingResultItem from '../../components/MatchMaking/MatchMakingItem';

import classes from './styles.module.scss';
/* import { DEFAULT_PAGE_SIZE_DETAIL_PAGE } from '../../constants/main'; */

const MatchMakingResultPage = ({ providers, isFetching }) => {
  return (
    <Layout inResultPage>
      <div className={classes.MatchMakingResultPage}>
        <div className={classes.container}>
          <div className={classes.infoText}>
            <div className={classes.key}>Company recommendations</div>
            <div className={classes.value}>
              {!isFetching && providers
                ? `${providers.length} results found`
                : null}
            </div>
          </div>
        </div>
        <div className={classes.itemsContainer}>
          <div className={classes.container}>
            {!isFetching && providers
              ? providers.map((item) => (
                  <MatchMakingResultItem
                    provider={item}
                    isVerified
                    key={item.id}
                  />
                ))
              : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default connect(
  (state) => {
    return {
      isFetching: state.providers.isFetching,
      providers: state.providers.items,
    };
  },
  {
    matchMakingProvidersAction: providersActions.matchMakingProviders,
  }
)(MatchMakingResultPage);
