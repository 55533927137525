import React from 'react';

import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import classes from '../styles.module.scss';

const ElectricalState = {
  electricalTierEquivalent: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4', 'Tier 5'],
  upsType: ['Static UPS', 'Constant UPS', 'Fixed UPS'],
  upsRedundancy: ['1N', '2N', '3N'],
  pduRedundancy: ['N+1', 'N+2', 'N+3'],
  generatorRedundancy: ['N+1', 'N+2', 'N+3'],
};

const ElectricalForm = ({
  values,
  setFieldValue,
  setFieldTouched,
  typeForm,
  errors,
  touched,
}) => {
  return (
    <div className={classes.ContainerForm}>
      <div className={classes.header}>
        <h1>Electrical</h1>
      </div>
      <div className={classes.container}>
        <div className={classes.row}>
          <span>Electrical Tier Equivalent</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.electricalTierEquivalent`,
                  event.target.value
                );
              }}
              onBlur={() =>
                setFieldTouched(`${typeForm}.electricalTierEquivalent`)
              }
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].electricalTierEquivalent &&
                touched[typeForm] &&
                touched[typeForm].electricalTierEquivalent
              }
              name="electricalTierEquivalent"
            >
              <option selected disabled>
                Please Select
              </option>
              {ElectricalState.electricalTierEquivalent.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.electricalTierEquivalent === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].electricalTierEquivalent ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].electricalTierEquivalent}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Total Provisioned UPS Power (kW)</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.totalProvisionedUPSPower}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.totalProvisionedUPSPower`,
                  event.target.value
                );
              }}
              onBlur={() =>
                setFieldTouched(`${typeForm}.totalProvisionedUPSPower`)
              }
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].totalProvisionedUPSPower &&
                touched[typeForm] &&
                touched[typeForm].totalProvisionedUPSPower
              }
              name="totalProvisionedUPSPower"
            />
            {errors[typeForm] && errors[typeForm].totalProvisionedUPSPower ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].totalProvisionedUPSPower}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>UPS Type</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.upsType`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.upsType`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].upsType &&
                touched[typeForm] &&
                touched[typeForm].upsType
              }
              name="upsType"
            >
              <option selected disabled>
                Please Select
              </option>
              {ElectricalState.upsType.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.upsType === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].upsType ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].upsType}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>UPS Redundancy</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.upsRedundancy`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.upsRedundancy`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].upsRedundancy &&
                touched[typeForm] &&
                touched[typeForm].upsRedundancy
              }
              name="upsRedundancy"
            >
              <option selected disabled>
                Please Select
              </option>
              {ElectricalState.upsRedundancy.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.upsRedundancy === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].upsRedundancy ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].upsRedundancy}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>PDU Redundancy</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(`${typeForm}.pduRedundancy`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.pduRedundancy`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].pduRedundancy &&
                touched[typeForm] &&
                touched[typeForm].pduRedundancy
              }
              name="pduRedundancy"
            >
              <option selected disabled>
                Please Select
              </option>
              {ElectricalState.pduRedundancy.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.pduRedundancy === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].pduRedundancy ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].pduRedundancy}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Generator Redundancy</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.generatorRedundancy`,
                  event.target.value
                );
              }}
              onBlur={() => setFieldTouched(`${typeForm}.generatorRedundancy`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].generatorRedundancy &&
                touched[typeForm] &&
                touched[typeForm].generatorRedundancy
              }
              name="generatorRedundancy"
            >
              <option selected disabled>
                Please Select
              </option>
              {ElectricalState.generatorRedundancy.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.generatorRedundancy === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].generatorRedundancy ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].generatorRedundancy}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Concurrently Maintainable Electric</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasConcurrentlyMaintainableElectric"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasConcurrentlyMaintainableElectric}
              onChange={() => {
                setFieldValue(
                  `${typeForm}.hasConcurrentlyMaintainableElectric`,
                  true
                );
              }}
            />
            <Form.Check
              name="hasConcurrentlyMaintainableElectric"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasConcurrentlyMaintainableElectric}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(
                  `${typeForm}.hasConcurrentlyMaintainableElectric`,
                  false
                );
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Min Power Capacity Per Rack (kW)</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.minPowerCapacityPerRack}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.minPowerCapacityPerRack`,
                  event.target.value
                );
              }}
              onBlur={() =>
                setFieldTouched(`${typeForm}.minPowerCapacityPerRack`)
              }
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].minPowerCapacityPerRack &&
                touched[typeForm] &&
                touched[typeForm].minPowerCapacityPerRack
              }
              name="minPowerCapacityPerRack"
            />
            {errors[typeForm] && errors[typeForm].minPowerCapacityPerRack ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].minPowerCapacityPerRack}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectricalForm;
