import React from 'react';

import classes from './styles.module.scss';

const ListFeatures = ({ features }) => {
  return (
    <div className={classes.itemsContainer}>
      {features.map((item) => (
        <div className={classes.item} key={item}>
          <div className={classes.checkmark}>
            <div className={classes.checkmarkInner} />
          </div>
          <div className={classes.description}>{item}</div>
        </div>
      ))}
    </div>
  );
};

export default ListFeatures;
