import { ROLE_ACTIONS } from '../constants/main';
import userAllowedActions from './userAllowedActions';

const firstAllowedRoute = () => {
  const { allowedAction, company } = userAllowedActions();
  if (allowedAction.includes(ROLE_ACTIONS.SELLER)) {
    if (company) {
      return '/dashboard/your-data-centers';
    }

    return '/company';
  }

  if (allowedAction.includes(ROLE_ACTIONS.BUYER)) return '/form';
  return '/login';
};

export default firstAllowedRoute;
