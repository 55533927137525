import React from 'react';
import classnames from 'classnames';

import classes from './styles.module.scss';

const SpecsTab = ({ provider }) => {
  return (
    <div className={classes.specsBox}>
      <div className={classes.box}>
        <div className={classes.boxItem}>
          <div className={classes.title}>GENERAL</div>
          <div className={classes.option}>
            <div className={classes.key}>Property Tier Equivalent</div>
            <div className={classes.value}>
              {provider.propertyTierEquivalent}
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Total Potential UPS Power</div>
            <div className={classes.value}>
              {provider.totalPotentialUPSPower}
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Gross Area Sq. Feet</div>
            <div className={classes.value}>{provider.grossAreaSqFeet}</div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Outside 100 Year Floodplain</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasOutside100YearFloodplain
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Outside 500 Year Floodplain</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasOutside500YearFloodplain
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Floor Load Capacity</div>
            <div className={classes.value}>{provider.floorLoadCapacity}</div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Fire Suppression Type</div>
            <div className={classes.value}>{provider.fireSuppressionType}</div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Fire Detection Type</div>
            <div className={classes.value}>{provider.fireDetectionType}</div>
          </div>
        </div>
        <div className={classes.boxItem}>
          <div className={classes.title}>ELECTRICAL</div>
          <div className={classes.option}>
            <div className={classes.key}>Electrical Tier Equivalent</div>
            <div className={classes.value}>
              {provider.electricalTierEquivalent}
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Total Provisioned UPS Power</div>
            <div className={classes.value}>
              {provider.totalProvisionedUPSPower}
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>UPS Type</div>
            <div className={classes.value}>{provider.upsType}</div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>UPS Redundancy</div>
            <div className={classes.value}>{provider.upsRedundancy}</div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>PDU Redundancy</div>
            <div className={classes.value}>{provider.pduRedundancy}</div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Generator Redundancy</div>
            <div className={classes.value}>{provider.generatorRedundancy}</div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>
              Concurrently Maintainable Electric
            </div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasConcurrentlyMaintainableElectric
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Min Power Capacity Per Rack</div>
            <div className={classes.value}>
              {provider.minPowerCapacityPerRack}
            </div>
          </div>
        </div>
        <div className={classes.boxItem}>
          <div className={classes.title}>CERTIFICATIONS</div>
          <div className={classes.option}>
            <div className={classes.key}>HITRUST</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasHITRUST
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>PCI DSS 3.2 Validation</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasPCIdss32Validation
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>ISO 27001 Compliant</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.isISO27001Compliant
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>NIST Compliant</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.isNISTCompliant
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>SOC 3 Compliant</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.isSOC3Compliant
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>HIPAA Compliant</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.isHIPAACompliant
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>SOC 2 Type 2 Compliant</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.isSOC2Type2Compliant
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.box}>
        <div className={classes.boxItem}>
          <div className={classes.title}>SECURITY</div>
          <div className={classes.option}>
            <div className={classes.key}>On-Site Security Personnel</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.onSiteSecurityPersonnel
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Mantrap</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasMantrap
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Ballistic Rated Exterior Walls</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasBallisticRatedExteriorWalls
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>24/7 Remote Monitoring</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasRemoteMonitoring
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Biometric Authentication</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasBiometricAuthentication
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Retina Scanners</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasRetinaScanners
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Keycard Access</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasKeycardAccess
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Dual Authentication</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasDualAuthentication
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>CCTV</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasCCTV
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Perimeter Intrusion Detection</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasPerimeterIntrusionDetection
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Electric Fence</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasElectricFence
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.boxItem}>
          <div className={classes.title}>MECHANICAL</div>
          <div className={classes.option}>
            <div className={classes.key}>Min Power Capacity Per Rack</div>
            <div className={classes.value}>
              {provider.mechanicalTierEquivalent}
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>CRAH/CRAC Redundancy</div>
            <div className={classes.value}>{provider.crahCRACRedundancy}</div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Chiller Redundancy</div>
            <div className={classes.value}>{provider.chillerRedundancy}</div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Cabinet Layout</div>
            <div className={classes.value}>{provider.cabinetLayout}</div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>
              Concurrently Maintainable Mechanical
            </div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasConcurrentlyMaintainableMechanical
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Min Cooling Capacity Per Rack</div>
            <div className={classes.value}>
              {provider.minCoolingCapacityPerRack}
            </div>
          </div>
        </div>
        <div className={classes.boxItem}>
          <div className={classes.title}>FEATURES</div>
          <div className={classes.option}>
            <div className={classes.key}>Internet Exchange</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasInternetExchange
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Gym</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasGym
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Remote Hands</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasRemoteHands
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
          <div className={classes.option}>
            <div className={classes.key}>Dual MPOE</div>
            <div className={classes.value}>
              <div
                className={classnames(
                  classes.checkmark,
                  provider.hasDualMPOE
                    ? classes.checkmarkChecked
                    : classes.checkmarkCancel
                )}
              >
                <div className={classes.checkmarkInner} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecsTab;
