import React, { useRef } from 'react';

import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { API_URL } from '../../constants/main';
import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Please enter your current password'),
  newPassword: Yup.string()
    .min(8, 'Too short')
    .required('Please enter password'),
  newPasswordConfirmation: Yup.string()
    .min(8, 'Too short')
    .required('Please repeat your password')
    .oneOf([Yup.ref('newPassword'), null], 'Password mismatch'),
});

export default function ChangePasswordModal({ show, onHide }) {
  const formRef = useRef();

  const notifyError = (message) => toast.error(message);

  const changePassword = (values) => {
    axios
      .post(`${API_URL}/change-password`, {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
      .then((response) => {
        console.log(response.data);
        onHide();
      })
      .catch((error) => {
        console.log(error.response.data.message);
        notifyError(error.response.data.message);
        formRef.current.resetForm();
      });
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Change password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          innerRef={formRef}
          initialValues={{
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values) => {
            changePassword(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            // isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  name="currentPassword"
                  type="password"
                  placeholder="Current password"
                  value={values.currentPassword}
                  onChange={handleChange}
                  isValid={touched.currentPassword && !errors.currentPassword}
                  isInvalid={touched.currentPassword && errors.currentPassword}
                  onBlur={handleBlur}
                />
                {touched.currentPassword && errors.currentPassword && (
                  <span className={classes.errorMessage}>
                    {errors.currentPassword}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  name="newPassword"
                  type="password"
                  placeholder="New password"
                  value={values.newPassword}
                  onChange={handleChange}
                  isValid={touched.newPassword && !errors.newPassword}
                  isInvalid={touched.newPassword && errors.newPassword}
                  onBlur={handleBlur}
                />
                {touched.newPassword && errors.newPassword && (
                  <span className={classes.errorMessage}>
                    {errors.newPassword}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  name="newPasswordConfirmation"
                  type="password"
                  placeholder="Re-type new password"
                  value={values.newPasswordConfirmation}
                  onChange={handleChange}
                  isValid={
                    touched.newPasswordConfirmation &&
                    !errors.newPasswordConfirmation
                  }
                  isInvalid={
                    touched.newPasswordConfirmation &&
                    errors.newPasswordConfirmation
                  }
                  onBlur={handleBlur}
                />
                {touched.newPasswordConfirmation &&
                  errors.newPasswordConfirmation && (
                    <span className={classes.errorMessage}>
                      {errors.newPasswordConfirmation}
                    </span>
                  )}
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            formRef.current.handleSubmit();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
}
