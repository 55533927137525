/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';

import classes from './styles.module.scss';
import TypeRadios from '../TypeRadios';

export default function TechnicalFormRacks({
  values,
  allValues,
  setFieldValue,
  setFieldTouched,
  typeForm,
  setCurrentPage,
  technicalFormPage,
  setTechnicalFormPage,
  isEditMode,
  noButtons,
  state,
  setState,
  errors,
  touched,
}) {
  const [oldState, setOldState] = useState({});

  useEffect(() => {
    setOldState({ ...values });
  }, []);

  const changeState = () => {
    setState((prev) => {
      return {
        ...prev,
        technicalFormWholesale: {
          space: {
            ...state.technicalFormWholesale.space,
            value: values.space,
          },
          power: {
            ...state.technicalFormWholesale.power,
            value: values.power,
          },
        },
      };
    });
  };

  const isValid = async () => {
    const result = await Promise.all([
      setFieldTouched(`${typeForm}.space`, true, true),
      setFieldTouched(`${typeForm}.power`, true, true),
    ]);
    const firstItem = result[0];
    if (firstItem.technicalFormWholesale) {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.Form}>
      <div className={classes.container}>
        <TypeRadios
          technicalFormPage={technicalFormPage}
          setTechnicalFormPage={setTechnicalFormPage}
          setFieldValue={setFieldValue}
          allValues={allValues}
        />
        <div className={classes.row}>
          <span>Space sq-ft</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.space}
              onChange={(event) => {
                setFieldValue(`${typeForm}.space`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.space`)}
              isInvalid={
                ((errors[typeForm] && errors[typeForm].space) ||
                  values.space === '') &&
                touched[typeForm] &&
                touched[typeForm].space
              }
              name="space"
            />
            {((errors[typeForm] && errors[typeForm].space) ||
              values.space === '') &&
            touched[typeForm] &&
            touched[typeForm].space ? (
              <Form.Control.Feedback type="invalid">
                {(errors[typeForm] && errors[typeForm].space) ||
                  'This field is required'}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Power (kW)</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.power}
              onChange={(event) => {
                setFieldValue(`${typeForm}.power`, event.target.value);
              }}
              onBlur={() => setFieldTouched(`${typeForm}.power`)}
              isInvalid={
                ((errors[typeForm] && errors[typeForm].power) ||
                  values.power === '') &&
                touched[typeForm] &&
                touched[typeForm].power
              }
              name="power"
            />
            {((errors[typeForm] && errors[typeForm].power) ||
              values.power === '') &&
            touched[typeForm] &&
            touched[typeForm].power ? (
              <Form.Control.Feedback type="invalid">
                {(errors[typeForm] && errors[typeForm].power) ||
                  'This field is required'}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div
          className={classes.buttonsContainer}
          style={{ display: noButtons ? 'none' : 'flex' }}
        >
          <button
            className={classes.formButton}
            type="button"
            onClick={() => {
              setCurrentPage((prevState) => {
                if (prevState === 1 && !isEditMode) {
                  return prevState;
                }
                if (isEditMode) {
                  setFieldValue(`${typeForm}`, oldState);
                  return 5;
                }
                return prevState - 1;
              });
            }}
          >
            {isEditMode ? 'Cancel' : 'Back'}
          </button>
          <button
            className={classes.formButton}
            type="button"
            onClick={async () => {
              await isValid();
              if (values.space && values.power) {
                setCurrentPage((prevState) => {
                  if (prevState === 4 && !isEditMode) {
                    return prevState;
                  }
                  if (isEditMode) {
                    changeState();
                    return 5;
                  }
                  changeState();
                  return prevState + 1;
                });
              }
            }}
          >
            {isEditMode ? 'Save' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
}
