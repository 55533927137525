import React from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import classes from './styles.module.scss';
import logo from '../../assets/images/logo.svg';
import { API_URL } from '../../constants/main';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

export default function ForgotPasswordPage() {
  const notifyError = (message) => toast.error(message);
  const notifySuccess = (message) => toast.success(message);

  return (
    <div className={classes.ForgotPasswordPage}>
      <img className={classes.logo} width="274" src={logo} alt="Logo" />
      <div className={classes.formContainer}>
        <p className={classes.title}>Forgot Your Password</p>
        <p className={classes.subTitle}>Please enter your e-mail address</p>
        <div className={classes.form}>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {
              axios
                .post(`${API_URL}/forgot-password`, { email: values.email })
                .then(() => {
                  notifySuccess('Email was sent');
                })
                .catch((error) => {
                  console.log(error);
                  notifyError(
                    error.response
                      ? error.response.data.message
                      : 'Something went wrong'
                  );
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              // isSubmitting,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className={classes.formGroup}>
                  <Form.Label className={classes.label}>
                    Enter your email address
                  </Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Enter your email address"
                    value={values.currentPassword}
                    onChange={handleChange}
                    isValid={touched.email && !errors.email}
                    isInvalid={touched.email && errors.email}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email && (
                    <span className={classes.errorMessage}>{errors.email}</span>
                  )}
                </Form.Group>
                <button
                  className={classes.submitButton}
                  type="button"
                  onClick={handleSubmit}
                >
                  Send Password Reset Link
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
