import React, { useEffect, useState } from 'react';

import ReactFileReader from 'react-file-reader';
import axios from 'axios';
import { connect } from 'react-redux';

import { actions } from '../../resources/users';
import Layout from '../../containers/Layout';
import ChangePasswordModal from '../../modals/ChangePasswordModal';

import { API_URL, STATIC_URL } from '../../constants/main';
import { getAuthData } from '../../helpers/authStorage';
import classes from './styles.module.scss';

function AccountPage({ getUser, user }) {
  const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] =
    useState(false);

  useEffect(() => {
    getUser(getAuthData().userId);
    document.body.style.backgroundColor = '#E5E5E5';
    document.body.style.margin = '0';
    return () => {
      document.body.style.backgroundColor = 'unset';
    };
  }, []);

  const showChangePasswordModal = () => {
    setIsChangePasswordModalVisible(true);
  };

  const hideChangePasswordModal = () => {
    setIsChangePasswordModalVisible(false);
  };

  const uploadAvatar = (files) => {
    const formData = new FormData();
    formData.append('file', files[0]);
    axios
      .post(`${API_URL}/users/uploadAvatar`, formData)
      .then(() => {
        getUser(getAuthData().userId);
      })
      .catch((error) => console.log(error));
  };

  return (
    <Layout>
      <div className={classes.AccountPage}>
        <div
          className={classes.avatar}
          style={{
            backgroundImage:
              user && user.avatarPath
                ? `url(${STATIC_URL}${user.avatarPath.replace(/\\/g, '/')})`
                : '',
          }}
        />
        <div className={classes.userMenu}>
          <h1>{user && user.fullName}</h1>
          <div className={classes.buttons}>
            <ReactFileReader
              handleFiles={uploadAvatar}
              fileTypes={['.gif', '.png', '.jpg', '.jpeg']}
            >
              <button type="button">Upload picture</button>
            </ReactFileReader>
            <button type="button" onClick={showChangePasswordModal}>
              Change password
            </button>
          </div>
        </div>
      </div>
      <ChangePasswordModal
        show={isChangePasswordModalVisible}
        onHide={hideChangePasswordModal}
      />
    </Layout>
  );
}

export default connect((state) => ({ user: state.users.item }), {
  logout: actions.logoutUser,
  getUser: actions.getUser,
})(AccountPage);
