/* import axios from 'axios'; */
import React from 'react';
import { STATIC_URL } from '../../../../constants/main';

import classes from './styles.module.scss';

export default function ImagePreview({ image, onDelete, isUpdate }) {
  const imageSrc = isUpdate
    ? `${STATIC_URL}${image.path}`
    : URL.createObjectURL(image);

  return (
    <div className={classes.ImagePreview}>
      <div
        className={classes.preview}
        /* style={{ backgroundImage: `url(${imageSrc})` }} */
      >
        <img src={imageSrc} alt="" className={classes.imgItem} />
      </div>
      <span className={classes.size}>
        {(image.size / 1024 / 1024).toFixed(2)} MB
      </span>
      <i
        className={classes.deleteIcon}
        tabIndex={0}
        role="button"
        onClick={onDelete}
      >
        delete
      </i>
    </div>
  );
}
