/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import ReactFileReader from 'react-file-reader';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import axios from 'axios';

import { actions as userActions } from '../../resources/users';

import Layout from '../../containers/Layout';

import { API_URL } from '../../constants/main';
import { clearAuthData, getAuthData } from '../../helpers/authStorage';

import classes from './styles.module.scss';
import PhoneField from '../../components/FormComponents/PhoneField';

const validationPhone = (value) => {
  let errorMessage;
  if (!isValidPhoneNumber(value)) {
    errorMessage = value ? 'Invalid phone number' : 'This field is required!';
  }
  return errorMessage;
};

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required!'),
  companyName: Yup.string().required('This field is required!'),
  logoPath: Yup.string().required('This field is required!'),
  email: Yup.string()
    .email('Invalid email')
    .required('This field is required!'),
  address: Yup.string().required('This field is required!'),
  website: Yup.string().required('This field is required!'),
});

const initialValues = {
  name: '',
  companyName: '',
  logoPath: '',
  email: '',
  address: '',
  website: '',
  phoneNo: '',
};

const CreateCompanyPage = ({ refreshUser }) => {
  const history = useHistory();
  const [companyState, setCompanyState] = useState(null);
  useEffect(() => {
    document.body.style.backgroundColor = '#E5E5E5';
    document.body.style.margin = '0';
    return () => {
      document.body.style.backgroundColor = 'unset';
    };
  }, []);

  const getFileFromUploader = (files, setFieldValue) => {
    setFieldValue('logoPath', files[0]);
  };

  const submitFormData = (values) => {
    const formData = new FormData();
    const formValues = {
      ...values,
    };

    delete formValues.logoPath;

    Object.keys(formValues).forEach((key) => {
      formData.append(key, formValues[key]);
    });

    formData.append('logo', values.logoPath);

    axios
      .post(`${API_URL}/company`, formData)
      .then(() => {
        const { refreshToken, userId } = getAuthData();
        refreshUser({ refreshToken, userId })
          .then(() => {
            history.push('/dashboard/your-data-centers');
          })
          .catch((err) => {
            clearAuthData();
            history.push('/login');
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkCompany = (value) => {
    axios
      .get(`${API_URL}/company/check`, {
        params: {
          companyName: value,
        },
      })
      .then((res) => {
        setCompanyState(res.data);
      })
      .catch(() => {
        setCompanyState(null);
      });
  };

  const onBlurAction = (companyName, setFieldTouched) => {
    setFieldTouched('companyName');
    checkCompany(companyName);
  };

  return (
    <>
      <Layout>
        <div className={classes.Form}>
          <div className={classes.container}>
            <div className={classes.header}>
              <h1>Sign Up Details</h1>
            </div>
            <Formik
              onSubmit={submitFormData}
              initialValues={initialValues}
              validationSchema={ValidationSchema}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                values,
                touched,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className={classes.row}>
                    <span>Name</span>
                    <div>
                      <Form.Control
                        className={classes.textInput}
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Type Name"
                        onBlur={handleBlur}
                        name="name"
                        isInvalid={
                          errors && errors.name && touched && touched.name
                        }
                      />
                      {errors.name && touched.name ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      ) : null}
                    </div>
                  </div>
                  <div className={classes.row}>
                    <span>Company Name</span>
                    <div>
                      <Form.Control
                        className={classes.textInput}
                        value={values.companyName}
                        onChange={handleChange}
                        onBlur={() =>
                          onBlurAction(values.companyName, setFieldTouched)
                        }
                        placeholder="Type Data Center Name"
                        name="companyName"
                        isInvalid={
                          errors &&
                          errors.companyName &&
                          touched &&
                          touched.companyName
                        }
                      />
                      {errors.companyName && touched.companyName ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.companyName}
                        </Form.Control.Feedback>
                      ) : null}
                      {companyState && (
                        <div className={classes.boxError}>
                          <span className={classes.errorMsg}>
                            This company is already managed by
                            {companyState ? ` ${companyState.user}` : ''}.
                          </span>
                          <span className={classes.access}>
                            Click to request access
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={classes.row}>
                    <span>Upload Logo</span>
                    <div>
                      <ReactFileReader
                        handleFiles={(files) =>
                          getFileFromUploader(files, setFieldValue)
                        }
                        multipleFiles={false}
                        fileTypes={['.jpg', '.png']}
                      >
                        <div
                          className={classes.fileUploader}
                          onClick={() => setFieldTouched('logoPath')}
                        >
                          <div className={classes.fileDetails}>
                            <span>
                              {values.logoPath.name
                                ? values.logoPath.name
                                : 'Choose File (.jpg, .png)'}
                            </span>
                          </div>
                          <button
                            type="button"
                            className={classes.uploadButton}
                          >
                            Upload
                          </button>
                        </div>
                      </ReactFileReader>
                      {errors.logoPath && touched.logoPath ? (
                        <div className={classes.errBox}>{errors.logoPath}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className={classes.row}>
                    <span>Company Email</span>
                    <div>
                      <Form.Control
                        className={classes.textInput}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Company Email"
                        name="email"
                        isInvalid={
                          errors && errors.email && touched && touched.email
                        }
                      />
                      {errors.email && touched.email ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      ) : null}
                    </div>
                  </div>
                  <div className={classes.row}>
                    <span>Company Address</span>
                    <div>
                      <Form.Control
                        className={classes.textInput}
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Company Address"
                        name="address"
                        isInvalid={
                          errors && errors.address && touched && touched.address
                        }
                      />
                      {errors.address && touched.address ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.address}
                        </Form.Control.Feedback>
                      ) : null}
                    </div>
                  </div>
                  <div className={classes.row}>
                    <span>Company Website</span>
                    <div>
                      <Form.Control
                        className={classes.textInput}
                        value={values.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter Website URL"
                        name="website"
                        isInvalid={
                          errors && errors.website && touched && touched.website
                        }
                      />
                      {errors.website && touched.website ? (
                        <Form.Control.Feedback type="invalid">
                          {errors.website}
                        </Form.Control.Feedback>
                      ) : null}
                    </div>
                  </div>
                  <div className={classes.row}>
                    <span>Phone No</span>
                    <div>
                      <Field
                        type="text"
                        name="phoneNo"
                        onChange={handleChange('phoneNo')}
                        onBlur={handleBlur('phoneNo')}
                        component={PhoneField}
                        validate={validationPhone}
                        isInvalid={!!(errors.phoneNo && touched.phoneNo)}
                      />
                      {errors.phoneNo && touched.phoneNo ? (
                        <div className={classes.errBox}>{errors.phoneNo}</div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className={classes.buttonsContainer}
                    style={{ display: 'flex' }}
                  >
                    {/* <button className={classes.formButton} type="button">
                      Cancel
                    </button> */}
                    <button className={classes.formButton} type="submit">
                      Sign Up
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default connect(null, {
  refreshUser: userActions.refreshUser,
})(CreateCompanyPage);
