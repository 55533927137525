import React from 'react';

import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';

import classnames from 'classnames';

import classes from './styles.module.scss';

const initialValues = {
  title: '',
  description: '',
};

const ValidationSchema = Yup.object().shape({
  title: Yup.string().required('This field is required!'),
  description: Yup.string().required('This field is required!'),
});

const FeedbackForm = ({ show, onHide, user, provider }) => {
  const requestForm = (values) => {
    console.log(values);
    console.log(user);
    console.log(provider);
    onHide();
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName={classes.modal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName={classes.modalContent}
    >
      <div className={classes.btnClose} onClick={() => onHide()}>
        <div className={classes.iconBtn} />
      </div>
      <Modal.Body style={{ padding: 0 }}>
        <div className={classes.feedbackForm}>
          <Formik
            onSubmit={requestForm}
            initialValues={initialValues}
            validationSchema={ValidationSchema}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <div className={classes.header}>
                  <h1>Additional requirements</h1>
                </div>
                <div className={classes.container}>
                  <div className={classes.row}>
                    <Form.Control
                      className={classes.textInput}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Title"
                      name="title"
                      isInvalid={
                        errors && errors.title && touched && touched.title
                      }
                    />
                    {errors.title && touched.title ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                  <div className={classes.row}>
                    <Form.Control
                      as="textarea"
                      className={classes.descriptionContainer}
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Description"
                      name="description"
                      isInvalid={
                        errors &&
                        errors.description &&
                        touched &&
                        touched.description
                      }
                    />
                    {errors.description && touched.description ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    ) : null}
                  </div>
                  <div className={classes.buttonsContainer}>
                    <button
                      type="button"
                      className={classnames(classes.formButton, classes.cancel)}
                      onClick={() => onHide()}
                    >
                      Cancel
                    </button>
                    <button type="submit" className={classes.formButton}>
                      Send
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default connect(
  (state) => ({
    user: state.users.item,
  }),
  null
)(FeedbackForm);
