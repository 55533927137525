import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import usePlacesAutocomplete from 'use-places-autocomplete';
/* import imgDataCenterMini1 from '../../../assets/images/logoDataCenter_Mini_1.svg'; */

import classes from './styles.module.scss';
import { STATIC_URL } from '../../../constants/main';

const ProviderItem = ({ dataCenterName, selectedLocation, address, logo }) => {
  const {
    ready,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000, */
    },
  });
  const [showLocation, setShowLocation] = useState(false);

  useEffect(() => {
    if (dataCenterName && ready) {
      setValue(dataCenterName);
    }
  }, [dataCenterName, ready]);

  return (
    <div className={classes.ProviderItem}>
      <div
        className={classnames(
          classes.itemContainer,
          showLocation && classes.isOpenBlock
        )}
        onClick={() => setShowLocation(!showLocation)}
      >
        <div className={classes.boxInner}>
          <div className={classes.itemLogo}>
            <img src={`${STATIC_URL}${logo.path}`} alt="" />
          </div>
          <div className={classes.description}>
            <div className={classes.name}>{dataCenterName}</div>
            <div className={classes.address}>{address}</div>
          </div>
        </div>
        <div className={classes.viewBtn}>
          <span className={classes.text}>View {data.length} Data Centers</span>
          <span
            className={classnames(
              classes.arrow,
              showLocation && classes.isOpen
            )}
          />
        </div>
      </div>
      {/* <div>{status === 'OK' && <ul>{renderSuggestions()}</ul>}</div> */}
      {showLocation ? (
        <div className={classes.locationBlock}>
          <div className={classes.itemsContainer}>
            {status === 'OK' && data
              ? data.map((item) => (
                  <div
                    key={item.description}
                    className={classes.item}
                    onClick={() => selectedLocation(item.description)}
                  >
                    <div className={classes.key}>KHH1</div>
                    <div className={classes.location}>{item.description}</div>
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProviderItem;
