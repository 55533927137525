import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import LoginPage from '../pages/Auth/LoginPage';
import SignUpPage from '../pages/Auth/SignUpPage';

import QuestionsBuyerForm from '../pages/QuestionsBuyerForm';
import AccountPage from '../pages/AccountPage';

import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';

import DetailsPage from '../pages/DetailsPage';
import MatchMakingResultPage from '../pages/MatchMakingResultPage';
import DataCenterProvidersPage from '../pages/DataCenterProvidersPage';

import CreateCompany from '../pages/CreateCompanyPage';

import PrivateRoute from '../containers/PrivateRoute';

import DashboardRoutes from './Dashboard';

import userAllowedActions from '../helpers/userAllowedActions';
import firstAllowedRoute from '../helpers/firstAllowedRoute';

import { ROLE_ACTIONS } from '../constants/main';

const Routes = () => {
  const history = useHistory();

  const userData = userAllowedActions();
  useEffect(() => {
    document.body.style.fontFamily = 'Roboto, sans-serif';
  }, []);

  useEffect(() => {
    if (userData) {
      history.push(firstAllowedRoute());
    }
  }, []);

  return (
    <Switch>
      <PrivateRoute path="/dashboard" component={DashboardRoutes} />
      <PrivateRoute exact path="/company" component={CreateCompany} />
      <PrivateRoute exact path="/account" component={AccountPage} />
      <PrivateRoute exact path="/details/:id" component={DetailsPage} />

      <PrivateRoute
        exact
        path="/match-making-result"
        component={MatchMakingResultPage}
      />
      <PrivateRoute
        exact
        path="/providers/:id"
        component={DataCenterProvidersPage}
      />

      {userData && userData.allowedAction.includes(ROLE_ACTIONS.BUYER) && (
        <Switch>
          <Route path="/form" exact component={QuestionsBuyerForm} />
          <Redirect to="/form" />
        </Switch>
      )}

      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/signup" component={SignUpPage} />
      <Route exact path="/forgot-password" component={ForgotPasswordPage} />
      <Route exact path="/reset-password" component={ResetPasswordPage} />
      <Route path="/form" exact component={QuestionsBuyerForm} />
      <Redirect to="/form" />
    </Switch>
  );
};

export default connect((state) => {
  return {
    user: state.users.item,
  };
}, null)(Routes);
