/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

// eslint-disable-next-line import/no-named-as-default
import UserMenu from './UserMenu';

import classes from './styles.module.scss';

export default function HeaderDashboard() {
  return (
    <div className={classes.HeaderDashboard}>
      <div />
      <UserMenu />
    </div>
  );
}
