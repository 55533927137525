import React from 'react';

import Header from '../../components/Layout/Header';

export default function Layout({ inResultPage, children }) {
  return (
    <div>
      <Header inResultPage={inResultPage} />
      {children}
    </div>
  );
}
