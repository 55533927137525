import React from 'react';

import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import classes from '../styles.module.scss';

const GeneralState = {
  propertyTierEquivalent: ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4', 'Tier 5'],
  fireSuppressionType: ['Dry-Pipe', 'Wet Pipe'],
  fireDetectionType: ['VESDA', 'None'],
};

const GeneralForm = ({
  values,
  setFieldValue,
  setFieldTouched,
  typeForm,
  errors,
  touched,
}) => {
  return (
    <div className={classes.ContainerForm}>
      <div className={classes.header}>
        <h1>General</h1>
      </div>
      <div className={classes.container}>
        <div className={classes.row}>
          <span>Property Tier Equivalent</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.propertyTierEquivalent`,
                  event.target.value
                );
              }}
              onBlur={() =>
                setFieldTouched(`${typeForm}.propertyTierEquivalent`)
              }
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].propertyTierEquivalent &&
                touched[typeForm] &&
                touched[typeForm].propertyTierEquivalent
              }
              name="propertyTierEquivalent"
            >
              <option selected disabled>
                Please Select
              </option>
              {GeneralState.propertyTierEquivalent.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.propertyTierEquivalent === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].propertyTierEquivalent ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].propertyTierEquivalent}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Total Potential UPS Power (kW)</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.totalPotentialUPSPower}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.totalPotentialUPSPower`,
                  event.target.value
                );
              }}
              onBlur={() =>
                setFieldTouched(`${typeForm}.totalPotentialUPSPower`)
              }
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].totalPotentialUPSPower &&
                touched[typeForm] &&
                touched[typeForm].totalPotentialUPSPower
              }
              name="totalPotentialUPSPower"
            />
            {errors[typeForm] && errors[typeForm].totalPotentialUPSPower ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].totalPotentialUPSPower}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Gross Area Sq. Feet</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.grossAreaSqFeet}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.grossAreaSqFeet`,
                  event.target.value
                );
              }}
              onBlur={() => setFieldTouched(`${typeForm}.grossAreaSqFeet`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].grossAreaSqFeet &&
                touched[typeForm] &&
                touched[typeForm].grossAreaSqFeet
              }
              name="grossAreaSqFeet"
            />
            {errors[typeForm] && errors[typeForm].grossAreaSqFeet ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].grossAreaSqFeet}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Outside 100 Year Floodplain</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasOutside100YearFloodplain"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasOutside100YearFloodplain}
              onChange={() => {
                setFieldValue(`${typeForm}.hasOutside100YearFloodplain`, true);
              }}
            />
            <Form.Check
              name="hasOutside100YearFloodplain"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasOutside100YearFloodplain}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasOutside100YearFloodplain`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Outside 500 Year Floodplain</span>
          <div className={classes.radioContainer}>
            <Form.Check
              name="hasOutside500YearFloodplain"
              bsPrefix={classnames('form-check', classes.radio)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              type="radio"
              label="YES"
              checked={values.hasOutside500YearFloodplain}
              onChange={() => {
                setFieldValue(`${typeForm}.hasOutside500YearFloodplain`, true);
              }}
            />
            <Form.Check
              name="hasOutside500YearFloodplain"
              bsPrefix={classnames('form-check', classes.radio, classes.red)}
              bsSwitchPrefix={classnames('form-switch', classes.radioSwitch)}
              checked={!values.hasOutside500YearFloodplain}
              type="radio"
              label="NO"
              onChange={() => {
                setFieldValue(`${typeForm}.hasOutside500YearFloodplain`, false);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <span>Floor Load Capacity (Lbs/Sqft)</span>
          <div>
            <Form.Control
              className={classes.textInput}
              value={values.floorLoadCapacity}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.floorLoadCapacity`,
                  event.target.value
                );
              }}
              onBlur={() => setFieldTouched(`${typeForm}.floorLoadCapacity`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].floorLoadCapacity &&
                touched[typeForm] &&
                touched[typeForm].floorLoadCapacity
              }
              name="floorLoadCapacity"
            />
            {errors[typeForm] && errors[typeForm].floorLoadCapacity ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].floorLoadCapacity}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Fire Suppression Type</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.fireSuppressionType`,
                  event.target.value
                );
              }}
              onBlur={() => setFieldTouched(`${typeForm}.fireSuppressionType`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].fireSuppressionType &&
                touched[typeForm] &&
                touched[typeForm].fireSuppressionType
              }
              name="fireSuppressionType"
            >
              <option selected disabled>
                Please Select
              </option>
              {GeneralState.fireSuppressionType.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.fireSuppressionType === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].fireSuppressionType ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].fireSuppressionType}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
        <div className={classes.row}>
          <span>Fire Detection Type</span>
          <div>
            <Form.Select
              className={classes.select}
              bsPrefix={classnames('form-select', classes.selectPrefix)}
              onChange={(event) => {
                setFieldValue(
                  `${typeForm}.fireDetectionType`,
                  event.target.value
                );
              }}
              onBlur={() => setFieldTouched(`${typeForm}.fireDetectionType`)}
              isInvalid={
                errors[typeForm] &&
                errors[typeForm].fireDetectionType &&
                touched[typeForm] &&
                touched[typeForm].fireDetectionType
              }
              name="fireDetectionType"
            >
              <option selected disabled>
                Please Select
              </option>
              {GeneralState.fireDetectionType.map((option) => (
                <option
                  key={option}
                  value={option}
                  selected={values.fireDetectionType === option}
                >
                  {option}
                </option>
              ))}
            </Form.Select>
            {errors[typeForm] && errors[typeForm].fireDetectionType ? (
              <Form.Control.Feedback type="invalid">
                {errors[typeForm].fireDetectionType}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralForm;
