/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import React from 'react';

import classes from './styles.module.scss';

export default function ProgressFlow({ currentPage, pageNames = [] }) {
  return (
    <div className={classes.ProgressFlow}>
      <div
        className={classNames(
          classes.progressBarContainer,
          currentPage === 5 && classes.finished
        )}
      >
        <div
          className={classNames(
            classes.step,
            currentPage === 1
              ? classes.current
              : currentPage > 1
              ? classes.finished
              : null
          )}
        >
          <div>1</div>
          <span>{pageNames[0]}</span>
        </div>
        <div
          className={classNames(
            classes.line,
            currentPage > 1 && classes.filled
          )}
        />
        <div
          className={classNames(
            classes.step,
            currentPage === 2
              ? classes.current
              : currentPage > 2
              ? classes.finished
              : null
          )}
        >
          <div>2</div>
          <span>{pageNames[1]}</span>
        </div>
        <div
          className={classNames(
            classes.line,
            currentPage > 2 && classes.filled
          )}
        />
        <div
          className={classNames(
            classes.step,
            currentPage === 3
              ? classes.current
              : currentPage > 3
              ? classes.finished
              : null
          )}
        >
          <div>3</div>
          <span>{pageNames[2]}</span>
        </div>
        <div
          className={classNames(
            classes.line,
            currentPage > 3 && classes.filled
          )}
        />
        <div
          className={classNames(
            classes.step,
            currentPage === 4
              ? classes.current
              : currentPage > 4
              ? classes.finished
              : null
          )}
        >
          <div>4</div>
          <span>{pageNames[3]}</span>
        </div>
        <div
          className={classNames(
            classes.line,
            currentPage > 4 && classes.filled
          )}
        />
        <div
          className={classNames(
            classes.step,
            currentPage === 5 ? classes.finished : null
          )}
        >
          <div>5</div>
          <span>{pageNames[4]}</span>
        </div>
      </div>
    </div>
  );
}
