import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { STATIC_URL } from '../../../constants/main';
import FeedbackForm from '../../../modals/FeedbackFormModal';

/* import imgDataCenterMini1 from '../../../assets/images/logoDataCenter_Mini_1.svg'; */

import classes from './styles.module.scss';

const MatchMakingItem = ({ isVerified, provider }) => {
  const history = useHistory();
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const logo = provider ? JSON.parse(provider.logoPath) : '';
  const [showDescription, setShowDescription] = useState(false);
  const contactQuote = (e) => {
    e.stopPropagation();
    setIsFeedbackModalVisible(true);
    console.log('contactQuote');
  };

  return (
    <>
      <div className={classes.MatchMakingItem}>
        {isVerified ? <div className={classes.verified} /> : null}
        <div
          className={classes.itemContainer}
          onClick={() => setShowDescription(!showDescription)}
        >
          <div className={classes.boxInner}>
            <div className={classes.itemLogo}>
              <img src={`${STATIC_URL}${logo.path}`} alt="" />
            </div>
            <div className={classes.description}>
              <div className={classes.name}>{provider.dataCenterName}</div>
              <div
                className={classes.address}
                onClick={() => history.push(`/providers/${provider.id}`)}
              >
                {provider.address}
              </div>
            </div>
          </div>
          <div className={classes.contactBtn} onClick={contactQuote}>
            Contact for Quote
          </div>
        </div>
        {showDescription ? (
          <div className={classes.descriptionBlock}>
            <div className={classes.itemsContainer}>
              {provider
                ? provider.features.map((item) => (
                    <div className={classes.item}>
                      <div className={classes.checkmark}>
                        <div className={classes.checkmarkInner} />
                      </div>
                      <div className={classes.description}>{item}</div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        ) : null}
      </div>
      <FeedbackForm
        show={isFeedbackModalVisible}
        onHide={setIsFeedbackModalVisible}
        provider={provider}
      />
    </>
  );
};

export default MatchMakingItem;
