import React from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

const RadioButton = ({
  id,
  changed,
  value,
  label,
  name,
  isSelected,
  isCrop,
  style,
}) => {
  return (
    <div className={classes.RadioButton} style={style}>
      <input
        id={id}
        onChange={changed}
        value={value}
        type="radio"
        checked={isSelected}
        name={name}
      />
      <label htmlFor={id} className={classnames(isCrop && classes.isCrop)}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
