import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import axios from 'axios';
import classnames from 'classnames';

import { API_URL } from '../../constants/main';
import classes from './styles.module.scss';
import logo from '../../assets/images/logo.svg';

export default function ResetPasswordPage() {
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (!query.get('token')) {
      setMessage('URL is invalid or expired');
    } else {
      axios
        .post(`${API_URL}/reset-password`, { token: query.get('token') })
        .then((response) => {
          console.log(response.data);
          setMessage('An email with a new password was sent');
          setIsSuccess(true);
        })
        .catch((error) => {
          console.log(error);
          setIsSuccess(false);
          setMessage(
            error.response
              ? error.response.data.message
              : 'Something went wrong'
          );
        });
    }
  }, []);

  return (
    <div className={classes.ResetPasswordPage}>
      <img className={classes.logo} width="274" src={logo} alt="Logo" />
      <div className={classes.container}>
        <p className={classes.title}>Forgot Your Password</p>
        <p
          className={classnames(classes.message, isSuccess && classes.success)}
        >
          {message}
        </p>
      </div>
    </div>
  );
}
