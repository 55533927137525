import React from 'react';
import ReactPaginate from 'react-paginate';
import classnames from 'classnames';

import { DEFAULT_PAGE_SIZE } from '../../../constants/main';

import ProviderItem from '../ProviderItem';

import classes from './styles.module.scss';

const ProvidersList = ({
  providers,
  pageIndex,
  totalItemsCount,
  onPageChange,
  updateProviderAction,
  deleteProviderAction,
  fetchProvidersOnPage,
}) => {
  const pageCount = Math.ceil(totalItemsCount / DEFAULT_PAGE_SIZE);

  const changePage = (page) => {
    if (pageIndex === page.selected) {
      return;
    }
    onPageChange(page.selected);
  };

  return (
    <div className={classes.PlacesList}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead className={classes.tHead}>
            <tr className={classes.titleContainer}>
              <th className={classes.item}>Data Center Name</th>
              <th className={classes.item}>Title</th>
              <th className={classes.item}>Email</th>
              <th className={classes.item}>Address</th>
              <th className={classes.item}>Website</th>
              <th className={classes.item}>Phone No</th>
              <th className={classes.item}> </th>
              <th className={classes.item}> </th>
              <th className={classes.item}> </th>
            </tr>
          </thead>
          <tbody>
            {providers
              ? providers.map((item) => (
                  <ProviderItem
                    key={item.id}
                    item={item}
                    updateProviderAction={updateProviderAction}
                    deleteProviderAction={deleteProviderAction}
                    fetchProvidersOnPage={fetchProvidersOnPage}
                  />
                ))
              : null}
          </tbody>
        </table>
        {providers.length ? (
          <div className={classes.pagination}>
            <ReactPaginate
              previousClassName={classes.previous}
              nextClassName={classes.next}
              activeClassName={classes.paginationActive}
              pageCount={pageCount}
              initialPage={pageIndex}
              onPageChange={changePage}
              previousLabel={
                <div
                  className={classnames(
                    classes.arrowBox,
                    classes.arrowLeftBox,
                    pageIndex === 0 && classes.disabledArrowLeft
                  )}
                />
              }
              nextLabel={
                <div
                  className={classnames(
                    classes.arrowBox,
                    classes.arrowRightBox,
                    pageIndex + 1 === pageCount && classes.disabledArrowRight
                  )}
                />
              }
              containerClassName={classes.paginationBttns}
              previousLinkClassName="previousBttn"
              nextLinkClassName="nextBttn"
              disabledClassName="paginationDisabled"
              pageRangeDisplayed={6}
              marginPagesDisplayed={4}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProvidersList;
